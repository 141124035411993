<template>
    <!-- <div class="table-container"> -->
    <p class="table-name">Primka</p>
    <DxDataGrid
        id="receiptTable"
        :data-source="receipt"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :focused-row-enabled="true"
        :row-alternation-enabled="true"
        :allow-column-resizing="true"
        @initialized="getReceiptTableInstance"
        @init-new-row="onInitNewRowReceipt"
        @row-inserting="onRowInsertingReceipt"
        @row-inserted="onRowInsertedReceipt"
        @editing-start="onEditingStartReceipt"
        @row-updating="onRowUpdatingReceipt"
        @row-updated="onRowUpdatedReceipt"
        @row-removing="onRowRemovingReceipt"
        @focused-row-changed="onFocusedRowChangedReceipt"
        @edit-canceled="onEditCanceledReceipt"
        @exporting="onExportingReceipt"
        edit-template="receiptEditor"
    >
        <DxPaging :page-size="20" />
        <DxExport :enabled="true" :formats="['pdf']" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 30]"
        />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="receiptTableState"
        />
        <DxToolbar>
            <DxItem
                id="addButton"
                :options="receiptAddButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="receiptRefreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="receiptExportButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="receiptFilterInputOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxTextBox"
            />
        </DxToolbar>
        <!-- <DxSearchPanel :visible="true" /> -->
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxEditing
            :allow-deleting="true"
            :allow-updating="true"
            :allow-adding="true"
            :use-icons="true"
            mode="popup"
        >
            <DxPopup
                :show-title="true"
                title="Informacije o primci"
                :toolbar-items="receiptPopupToolbarItems"
                :drag-outside-boundary="true"
                @shown="onAddPopupShown"
                @hidden="onAddPopupHidden"
            />
            <DxForm @initialized="getReceiptFormInstance">
                <DxItem :col-count="3" :col-span="2" item-type="group">
                    <DxItem
                        data-field="id"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem item-type="group" col-count="3">
                        <DxItem
                            data-field="number"
                            data-type="number"
                            col-span="2"
                            :editor-options="numberOptions"
                        >
                            <DxRequiredRule />
                            <DxRangeRule :min="1" :max="2147483648" />
                        </DxItem>
                        <DxItem
                            data-field="businessYearId"
                            data-type="number"
                            :editor-options="businessYearSelectBoxOptions"
                        >
                            <DxLabel :visible="false"></DxLabel
                        ></DxItem>
                    </DxItem>
                    <DxItem
                        data-field="supplierId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        col-span="5"
                        :editor-options="supplierSelectBoxOptions"
                    >
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem
                        css-class="buttonTest"
                        editor-type="dxButton"
                        col-span="1"
                        :editor-options="addNewSupplierButtonOptions"
                    />

                    <DxItem
                        data-field="warehouseId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :editor-options="warehouseSelectBoxOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem data-field="supplierInvoice" data-type="string"
                        ><DxRequiredRule
                    /></DxItem>

                    <DxItem data-field="calculationId" data-type="number" />
                    <DxItem
                        data-field="date"
                        data-type="date"
                        :editor-options="dateSelectOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="supplierDispatchNote"
                        data-type="string"
                    />

                    <DxItem data-field="dueDate" data-type="date" />
                    <DxItem
                        data-field="remark"
                        data-type="string"
                        :col-span="2"
                    >
                        <DxStringLengthRule
                            :max="250"
                            message="Opis ne može biti više od 250 znakova."
                        />
                    </DxItem>
                    <DxItem
                        data-field="modifiedById"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem
                        data-field="modifiedDate"
                        data-type="datetime"
                        :visible="false"
                    />
                    <DxItem item-type="group" :col-span="2"></DxItem>
                    <DxItem col-span="3">
                        <template #default>
                            <DxDataGrid
                                id="receiptItemTable"
                                :data-source="receiptItemData"
                                :show-column-lines="true"
                                :show-row-lines="true"
                                :show-borders="true"
                                :column-auto-width="true"
                                @initialized="getReceiptItemTableInstance"
                                @editing-start="onEditingStartReceiptItem"
                                @row-inserting="onRowInsertingReceiptItem"
                            >
                                <DxPaging :page-size="10" />
                                <DxPager
                                    :visible="true"
                                    display-mode="adaptive"
                                    :show-info="true"
                                    info-text="Stranica {0} od {1} ({2} stavki)"
                                    :allowed-page-sizes="[5, 10, 15]"
                                    :show-navigation-buttons="true"
                                    :show-page-size-selector="true"
                                />
                                <DxEditing
                                    :allow-deleting="true"
                                    :allow-updating="true"
                                    :allow-adding="true"
                                    :use-icons="true"
                                    mode="popup"
                                >
                                    <DxPopup
                                        :max-height="350"
                                        :max-width="650"
                                        :show-title="true"
                                        title="Informacije o stavci"
                                        :toolbar-items="
                                            receiptItemPopupToolbarItems
                                        "
                                        :drag-outside-boundary="true"
                                        @shown="onItemPopupShown"
                                        @hidden="onItemPopupHidden"
                                    />
                                    <DxForm>
                                        <DxItem
                                            :col-count="10"
                                            :col-span="2"
                                            item-type="group"
                                        >
                                            <DxItem
                                                data-field="priceListItemId"
                                                data-type="number"
                                                editor-type="dxSelectBox"
                                                :editor-options="
                                                    priceListItemSelectBoxOptions
                                                "
                                                :col-span="9"
                                            >
                                                <DxRequiredRule
                                            /></DxItem>
                                            <DxItem
                                                :col-span="1"
                                                editor-type="dxButton"
                                                :editor-options="
                                                    addNewPriceListItemButtonOptions
                                                "
                                            />
                                        </DxItem>
                                        <DxItem
                                            :col-count="2"
                                            :col-span="2"
                                            item-type="group"
                                        >
                                            <DxItem
                                                data-field="price"
                                                data-type="number"
                                                :editor-options="priceOptions"
                                            >
                                                <DxRangeRule
                                                    :min="0"
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="qty"
                                                data-type="number"
                                                :editor-options="qtyOptions"
                                            >
                                                <DxRangeRule
                                                    :min="0"
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="priceTotal"
                                                data-type="number"
                                                :editor-options="
                                                    priceTotalOptions
                                                "
                                            >
                                                <DxRangeRule
                                                    :min="0"
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="Stanje na skladištu"
                                                data-type="number"
                                                :editor-options="
                                                    warehouseStatusOptions
                                                "
                                            />
                                        </DxItem>
                                    </DxForm>
                                </DxEditing>
                                <DxColumnFixing :enabled="true" />
                                <DxColumn
                                    caption="Rb."
                                    :width="50"
                                    alignment="center"
                                    :calculate-cell-value="setRowIndexItem"
                                    :allow-hiding="false"
                                    :show-in-column-chooser="false"
                                />
                                <DxColumn
                                    data-field="priceListItemId"
                                    data-type="number"
                                    caption="Roba"
                                >
                                    <DxLookup
                                        :data-source="priceListItem"
                                        value-expr="id"
                                        displayExpr="name"
                                    />
                                </DxColumn>
                                <DxColumn
                                    width="120"
                                    data-field="price"
                                    data-type="number"
                                    caption="Nabavna cijena"
                                    format="###,###,###,##0.00"
                                />
                                <DxColumn
                                    width="120"
                                    data-field="qty"
                                    data-type="number"
                                    caption="Količina"
                                    format="###,###,###,##0.00"
                                />
                                <DxColumn
                                    width="120"
                                    data-field="priceTotal"
                                    data-type="number"
                                    caption="Ukupna cijena"
                                    format="###,###,###,##0.00"
                                />
                                <DxSummary>
                                    <DxTotalItem
                                        column="priceTotal"
                                        summary-type="sum"
                                        :value-format="priceTotalFormat"
                                        display-format="{0}"
                                    />
                                </DxSummary>
                                <DxColumn
                                    type="buttons"
                                    :auto-width="true"
                                    :fixed="true"
                                >
                                    <DxButton
                                        hint="Uredi"
                                        icon="rename"
                                        :visible="true"
                                        @click="editReceiptItem"
                                    />
                                    <DxButton
                                        hint="Obriši"
                                        icon="clear"
                                        :visible="true"
                                        @click="deleteReceiptItem"
                                    />
                                </DxColumn>
                            </DxDataGrid>
                        </template>
                    </DxItem>
                </DxItem>
            </DxForm>
        </DxEditing>
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            :visible="false"
            :allow-exporting="false"
            :allow-header-filtering="false"
            :show-in-column-chooser="false"
        />
        <DxColumn
            data-field="number"
            data-type="number"
            caption="Broj"
            :allow-hiding="false"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="businessYearId"
            data-type="number"
            caption="God."
            :allow-exporting="false"
            :allow-header-filtering="false"
        >
            <DxLookup
                :data-source="businessYear"
                value-expr="id"
                display-expr="year"
            />
        </DxColumn>
        <DxColumn
            data-field="warehouseId"
            data-type="number"
            caption="Skladište"
        >
            <DxLookup
                :data-source="warehouse"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="date"
            data-type="date"
            caption="Datum"
            format="dd/MM/yyyy"
        >
            <DxHeaderFilter :groupInterval="'month'" />
        </DxColumn>
        <DxColumn
            data-field="supplierId"
            data-type="number"
            caption="Dobavljač"
            :allow-header-filtering="false"
        >
            <DxLookup
                :data-source="supplier"
                value-expr="id"
                displayExpr="companyName"
            />
        </DxColumn>
        <DxColumn
            data-field="calculationId"
            data-type="number"
            caption="Kalkulacija"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="remark"
            data-type="string"
            caption="Opis"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="priceTotal"
            data-type="number"
            caption="Iznos"
            format="###,###,###,##0.00"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="supplierInvoice"
            data-type="string"
            caption="Broj računa"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="supplierDispatchNote"
            data-type="string"
            caption="Broj otpremnice"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="dueDate"
            data-type="date"
            caption="Dospijeće"
            format="dd/MM/yyyy"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="status"
            data-type="string"
            caption="Status"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="createdById"
            data-type="number"
            caption="Kreirao"
            :allow-exporting="false"
            :allow-header-filtering="false"
        >
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="createdDate"
            data-type="datetime"
            caption="Kreirano"
            format="dd/MM/yyyy HH:mm"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="modifiedById"
            data-type="number"
            caption="Uredio"
            :allow-exporting="false"
            :allow-header-filtering="false"
        >
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            caption="Uređeno"
            format="dd/MM/yyyy HH:mm"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />

        <DxColumn
            data-field="domainId"
            data-type="number"
            caption="Domena"
            :visible="false"
            :allow-hiding="false"
            :show-in-column-chooser="false"
            :allow-exporting="false"
            :allow-header-filtering="false"
        >
            <DxLookup
                :data-source="domain"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton
                hint="Uredi"
                icon="rename"
                @click="editReceipt"
                :visible="isNotDeleted"
            />
            <DxButton
                hint="Obriši"
                icon="trash"
                @click="deleteReceipt"
                :visible="isNotDeleted"
            />
            <DxButton hint="Dupliciraj" icon="copy" @click="duplicateReceipt" />
            <DxButton
                hint="Izradi PDF"
                icon="pdffile"
                @click="exportReceipt"
                :visible="isNotDeleted"
            />
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
        <DxSummary>
            <DxTotalItem
                column="priceTotal"
                summary-type="sum"
                :value-format="priceTotalFormat"
                display-format="UKUPNO: {0}"
            />
        </DxSummary>
    </DxDataGrid>
    <AddPopup
        :show-title="true"
        title="Informacije o stavci"
        :drag-outside-boundary="true"
        :toolbar-items="addPopupToolbarItems"
        @initialized="getAddPopupInstance"
        @hiding="cancelNewPriceListItem"
    >
        <AddForm
            :form-data="formData"
            @initialized="getAddFormInstance"
            validationGroup="addPriceListItem"
        >
            <DxItem :col-count="3" :col-span="2" item-type="group">
                <DxItem data-field="id" data-type="number" :visible="false" />
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Cijenik"
                >
                    <DxItem
                        data-field="priceListId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Cijenik' }"
                        :editor-options="priceListSelectBoxOptions"
                        ><DxRequiredRule
                    /></DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Osnovne informacije"
                >
                    <DxItem
                        data-field="code"
                        data-type="string"
                        :label="{ text: 'Šifra' }"
                    >
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem
                        data-field="name"
                        data-type="string"
                        :label="{ text: 'Naziv' }"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="stockNumber"
                        data-type="string"
                        :label="{ text: 'Kataloški broj' }"
                    />
                    <DxItem
                        data-field="foreignName"
                        data-type="string"
                        :label="{ text: 'Strani naziv' }"
                    />
                    <DxItem
                        data-field="ean"
                        data-type="string"
                        :label="{ text: 'EAN' }"
                    />
                    <DxItem
                        data-field="customsNumber"
                        data-type="string"
                        :label="{ text: 'Carinski broj' }"
                    />
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Tip/Dobavljač"
                >
                    <DxItem
                        data-field="measureUnit"
                        data-type="string"
                        :label="{ text: 'JMJ' }"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="vatId"
                        data-type="number"
                        :label="{ text: 'PDV' }"
                        editor-type="dxSelectBox"
                        :editor-options="vatIdOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="itemType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Roba/Usluga' }"
                        :editor-options="itemTypeOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="supplierId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Dobavljač' }"
                        :editor-options="supplierSelectBoxOptions"
                    ></DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Informacije o cijeni"
                >
                    <DxItem
                        data-field="latestProcurementPrice"
                        data-type="number"
                        :disabled="true"
                        :label="{ text: 'Zadnja nabavna' }"
                    />
                    <DxItem
                        data-field="priceCalculationType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Izračun cijene' }"
                        :editor-options="priceCalculationTypeOptions"
                    />
                    <DxItem
                        data-field="wholesalePrice"
                        data-type="number"
                        :label="{ text: 'Veleprodajna' }"
                        :editor-options="wholesalePriceOptions"
                    >
                        <DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="vatAmount"
                        data-type="number"
                        :label="{ text: 'Iznos PDV-a' }"
                        :editor-options="vatAmountOptions"
                    />
                    <DxItem
                        data-field="retailPrice"
                        data-type="number"
                        :label="{ text: 'Maloprodajna' }"
                        :editor-options="retailPriceOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="margin"
                        data-type="number"
                        :label="{ text: 'Marža' }"
                    />
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Ostale informacije"
                >
                    <DxItem
                        data-field="returnPackagingQty"
                        data-type="number"
                        :label="{ text: 'Povratna ambalaža' }"
                    />
                    <DxItem
                        data-field="orderedQty"
                        data-type="number"
                        :label="{ text: 'Naručeno' }"
                    />
                    <DxItem
                        data-field="minStock"
                        data-type="number"
                        :label="{ text: 'Min. zaliha' }"
                    />
                    <DxItem
                        data-field="maxStock"
                        data-type="number"
                        :label="{ text: 'Max. zaliha' }"
                    />
                    <DxItem
                        data-field="hidden"
                        data-type="number"
                        :editor-options="hiddenOptions"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Skriveno' }"
                    />
                </DxItem>
            </DxItem>
        </AddForm>
    </AddPopup>
    <AddPopup
        :show-title="true"
        title="Informacije o dobavljaču"
        :drag-outside-boundary="true"
        :toolbar-items="addSupplierPopupToolbarItems"
        @initialized="getAddSupplierPopupInstance"
        @hiding="cancelNewSupplier"
    >
        <AddForm
            :form-data="formDataSupplier"
            @initialized="getAddSupplierFormInstance"
            validationGroup="addSupplier"
        >
            <DxItem :col-count="3" :col-span="2" item-type="group">
                <DxItem :col-count="1" :col-span="3" item-type="group">
                    <DxButtonItem
                        :button-options="fetchCompanyDataButtonOptions"
                        horizontal-alignment="left"
                    />
                </DxItem>
                <DxItem data-field="id" data-type="number" :visible="false" />
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Informacije o dobavljaču"
                >
                    <DxItem
                        data-field="vatNumber"
                        data-type="string"
                        :label="{ text: 'OIB' }"
                        :editor-options="oibOptions"
                    >
                        <DxRequiredRule />
                        <DxStringLengthRule
                            :max="11"
                            :min="11"
                            message="OIB mora sadržavati 11 brojeva."
                        />
                    </DxItem>
                    <DxItem
                        data-field="name"
                        data-type="string"
                        :label="{ text: 'Ime' }"
                    >
                    </DxItem>
                    <DxItem
                        data-field="lastname"
                        data-type="string"
                        :label="{ text: 'Prezime' }"
                    >
                    </DxItem>
                    <DxItem
                        data-field="companyName"
                        data-type="string"
                        :label="{ text: 'Naziv firme' }"
                        :editor-options="companyNameOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="invoiceType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Tip računa' }"
                        :editor-options="invoiceTypeSelectBoxOptions"
                    >
                        <DxRequiredRule
                    /></DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Adresa/Kontakt"
                >
                    <DxItem
                        data-field="zipCode"
                        data-type="string"
                        :label="{ text: 'Poštanski broj' }"
                        :editor-options="zipCodeOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="city"
                        data-type="string"
                        :label="{ text: 'Grad' }"
                        :editor-options="cityOptions"
                    >
                    </DxItem>

                    <DxItem
                        data-field="street"
                        data-type="string"
                        :label="{ text: 'Adresa' }"
                        :editor-options="streetOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="country"
                        data-type="string"
                        :label="{ text: 'Država' }"
                        :editor-options="countryOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="region"
                        data-type="string"
                        :label="{ text: 'Regija' }"
                    >
                    </DxItem>
                    <DxItem
                        data-field="contact"
                        data-type="string"
                        :label="{ text: 'Kontakt' }"
                    >
                    </DxItem>
                    <DxItem
                        data-field="phone"
                        data-type="string"
                        :label="{ text: 'Telefon' }"
                    >
                    </DxItem>
                    <DxItem
                        data-field="fax"
                        data-type="string"
                        :label="{ text: 'Fax' }"
                    >
                    </DxItem>
                    <DxItem
                        data-field="email"
                        data-type="string"
                        :label="{ text: 'E-mail' }"
                        :editor-options="emailOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="web"
                        data-type="string"
                        :label="{ text: 'Web' }"
                    >
                    </DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Ostale informacije"
                >
                    <DxItem
                        data-field="discount"
                        data-type="number"
                        :label="{ text: 'Popust' }"
                    />
                    <DxItem
                        data-field="profitCenter"
                        data-type="string"
                        :label="{ text: 'Profitni centar' }"
                    />
                </DxItem>
            </DxItem>
        </AddForm>
    </AddPopup>
    <AddPopup
        :resize-enabled="true"
        :max-width="1000"
        :drag-outside-boundary="true"
        :toolbarItems="sudregPopupToolbarItems"
        title="Učitaj podatke iz sudskog registra"
        @initialized="getSudregPopupInstance"
    >
        <DxDataGrid
            id="sudregDataTable"
            :data-source="sudregApi"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            no-data-text="Započnite pretraživanje"
            @initialized="getSudregDataTableInstance"
        >
            <DxToolbar>
                <DxItem
                    location="before"
                    locate-in-menu="auto"
                    widget="dxTextBox"
                    :options="searchBarOptions"
                />
            </DxToolbar>
            <DxPaging :page-size="20" />
            <DxColumnFixing :enabled="true" />
            <DxPager
                :allowed-page-sizes="[]"
                :visible="true"
                display-mode="adaptive"
                :show-info="true"
                info-text="Stranica {0} od {1} ({2} stavki)"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxColumn
                type="buttons"
                :width="70"
                :show-in-column-chooser="false"
                :fixed="true"
                caption="Učitaj"
                fixed-position="left"
            >
                <DxButton
                    hint="Učitaj"
                    icon="chevrondoubleright"
                    :visible="true"
                    @click="fetchCompanyData"
                />
            </DxColumn>
            <DxColumn data-field="mbs" data-type="string" caption="MBS" />
            <DxColumn data-field="oib" data-type="string" caption="OIB" />
            <DxColumn data-field="ime" data-type="string" caption="Naziv" />
        </DxDataGrid>
    </AddPopup>
    <!-- </div> -->
    <div class="split"></div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    // DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
    DxExport
} from "devextreme-vue/data-grid";
import { confirm } from "devextreme/ui/dialog";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import { DxPopup as AddPopup } from "devextreme-vue/popup";
import { DxForm as AddForm } from "devextreme-vue/form";
import eventBus from "../../eventBus.js";
import { ref, onMounted } from "vue";
import { useReceipt } from "@/composables/useReceipt";
import { usePriceListItem } from "@/composables/usePriceListItem";
import { useSupplier } from "@/composables/useSupplier.js";
import { useSudregApiStore } from "@/stores/customSudregApiStore";
import { DxButtonItem } from "devextreme-vue/cjs/form";
export default {
    name: "receiptTableComponent",
    components: {
        AddPopup,
        AddForm,
        DxForm,
        DxItem,
        DxPopup,
        DxPager,
        DxLabel,
        DxColumn,
        DxButton,
        DxLookup,
        DxPaging,
        DxExport,
        DxEditing,
        DxToolbar,
        DxSummary,
        DxDataGrid,
        DxTotalItem,
        DxRangeRule,
        DxScrolling,
        DxButtonItem,
        // DxSearchPanel,
        DxColumnFixing,
        DxHeaderFilter,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxStringLengthRule,
        DxColumnChooserSelection
    },
    setup() {
        onMounted(() => {
            document.addEventListener("keydown", keyDownEvent, false);
        });
        //table instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);
        const addPopupInstance = ref(null);
        const addSupplierPopupInstance = ref(null);
        const sudregPopupInstance = ref(null);
        const sudregApiDataTableInstance = ref(null);

        //items instances
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const numberInstance = ref(null);
        const warehouseInstance = ref(null);
        const priceTotalInstance = ref(null);
        const dateSelectInstance = ref(null);
        const receiptFormInstance = ref(null);
        const priceListItemInstance = ref(null);
        const warehouseStatusInstance = ref(null);
        const vatAmountInstance = ref(null);
        const wholesalePriceInstance = ref(null);
        const retailPriceInstance = ref(null);
        const addFormInstance = ref(null);
        const addSupplierFormInstance = ref(null);
        const oibInstance = ref(null);
        const companyNameInstance = ref(null);
        const zipCodeInstance = ref(null);
        const cityInstance = ref(null);
        const streetInstance = ref(null);
        const countryInstance = ref(null);
        const emailInstance = ref(null);
        const searchBarInstance = ref(null);

        //general data
        const searchValueReceipt = ref(null);
        const qty = ref(1);
        const price = ref(0);
        const number = ref(null);
        const priceTotal = ref(null);
        const selectedDate = ref(null);
        const selectedRowKey = ref(null);
        const priceListItemId = ref(null);
        const addPopupOpened = ref(null);
        const addItemPopupOpened = ref(null);
        const duplicateReceiptData = ref(null);
        const formData = ref({
            vatId: 1,
            hidden: 0,
            priceCalculationType: "Fiksna cijena"
        });
        const formDataSupplier = ref({});
        const {
            admin,
            domain,
            domainUser,
            customer,
            receipt,
            supplier,
            warehouse,
            receiptItem,
            priceList,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            rowsToBeDeleted,
            businessYearId,
            receiptItemData,
            columnChooserMode,
            priceTotalFormat,
            handleRowInsertingReceipt,
            handleRowInsertedReceipt,
            handleEditingStartReceipt,
            handleEditCanceledReceipt,
            handleRowUpdatingReceipt,
            handleRowRemovingReceipt,
            handleExportingReceiptRecap,
            handleCancelReceiptItem,
            handleSaveNewReceiptItem,
            handleEditingStartReceiptItem,
            handleRowInsertingReceiptItem
        } = useReceipt();
        const {
            tax,
            vatId,
            vatValue,
            vatAmount,
            retailPrice,
            hiddenOptions,
            wholesalePrice,
            itemTypeOptions,
            updateRetailPrice,
            updateWholesalePrice,
            priceCalculationTypeOptions
        } = usePriceListItem();
        const { sudregApi, invoiceTypeSelectBoxOptions } = useSupplier();
        const sudregApiStore = useSudregApiStore();

        //button item options
        const receiptAddButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novu primku",
            onClick: () => addReceipt()
        });
        const receiptItemAddButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novu stavku",
            onClick: () => addReceiptItem()
        });
        const receiptRefreshButtonOptions = ref({
            icon: "refresh",
            hint: "Osvježi tablicu",
            onClick: () => refreshData()
        });
        const receiptExportButtonOptions = ref({
            icon: "export",
            hint: "Rekapitulacija primke",
            onClick: () => onExportingReceipt()
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            hint: "Odaberi stupce",
            onClick: () => openColumnChooser()
        });
        const searchBarOptions = ref({
            width: 240,
            label: "Pretraživanje",
            labelMode: "floating",
            onInitialized: (e) => {
                searchBarInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                eventBus.emit("searchValue", e.value);
                sudregApiDataTableInstance.value.refresh();
            }
        });
        const fetchCompanyDataButtonOptions = ref({
            text: "Učitaj podatke iz sudskog registra",
            stylingMode: "contained",
            width: 300,
            type: "default",
            onClick: () => {
                openSudregPopup();
            }
        });

        //toolbar items
        const receiptPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewReceipt()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelReceipt()
                }
            }
        ]);
        const receiptItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewReceiptItem()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelReceiptItem()
                }
            }
        ]);
        const addPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewPriceListItem()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelNewPriceListItem()
                }
            }
        ]);
        const addSupplierPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewSupplier()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelNewSupplier()
                }
            }
        ]);
        const sudregPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => sudregPopupInstance.value.hide()
                }
            }
        ]);

        //form item options
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            }
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            }
        });
        const numberOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: (e) => {
                number.value = e.value;
                numberInstance.value.option("disabled", false);
            }
        });
        const supplierSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: supplier.value,
                paginate: true,
                pageSize: 30
            }),
            valueExpr: "id",
            displayExpr: "companyName",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi dobavljača"
        });
        const priceListSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceList.value,
                paginate: true,
                pageSize: 30
            }),
            valueExpr: "id",
            displayExpr: "name",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi cijenik"
        });
        const warehouseSelectBoxOptions = ref({
            dataSource: warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                warehouseInstance.value = e.component;
            },
            onSelectionChanged: async (e) => {
                if (!warehouseInstance.value.option("disabled")) {
                    const loadOptions = {
                        warehouseId: e.selectedItem.id,
                        businessYearId: businessYearId.value
                    };
                    const res = await receipt.value.load(loadOptions);
                    if (res.length > 0) {
                        number.value = res[0].number + 1;
                        numberInstance.value.option("value", number.value);
                    } else {
                        number.value = 1;
                        numberInstance.value.option("value", 1);
                    }
                }
            }
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceListItem.value,
                paginate: true,
                pageSize: 30
            }),
            displayExpr: (data) => {
                if (data) return data.code + " | " + data.name;
            },
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                const selectedWarehouseId =
                    warehouseInstance.value.option("value");
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const res = await priceListItem.value.byKey(e.value);
                if (res.latestProcurementPrice !== null) {
                    priceInstance.value.option(
                        "value",
                        res.latestProcurementPrice
                    );
                } else {
                    priceInstance.value.option("value", 0);
                }
                priceListItemId.value = e.value;
            }
        });
        const vatAmountOptions = {
            value: vatAmount.value,
            format: "###,###,###,##0.00",
            readOnly: true,
            onInitialized: (e) => {
                vatAmountInstance.value = e.component;
            }
        };
        const vatIdOptions = {
            dataSource: tax.value,
            valueExpr: "id",
            displayExpr: "taxValue",
            placeholder: "Odaberi stopu poreza",
            onValueChanged: async (e) => {
                if (e.value !== null) {
                    vatId.value = e.value;
                    formData.value.vatId = e.value;
                    await updateWholesalePrice(
                        wholesalePrice.value,
                        wholesalePriceInstance.value,
                        retailPriceInstance.value,
                        vatAmountInstance.value
                    );
                }
            }
        };
        const wholesalePriceOptions = {
            value: wholesalePrice.value,
            format: "###,###,###,##0.00",
            // format: { style: 'currency'},
            onInitialized: (e) => {
                wholesalePriceInstance.value = e.component;
                wholesalePriceInstance.value.option(
                    "value",
                    wholesalePrice.value
                );
            },
            onValueChanged: async (e) => {
                if (e.value !== null)
                    await updateWholesalePrice(
                        e,
                        wholesalePriceInstance.value,
                        retailPriceInstance.value,
                        vatAmountInstance.value
                    );
            }
        };
        const retailPriceOptions = {
            value: retailPrice.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                retailPriceInstance.value = e.component;
                retailPriceInstance.value.option("value", retailPrice.value);
            },
            onValueChanged: async (e) => {
                if (e.value !== null)
                    await updateRetailPrice(
                        e,
                        retailPriceInstance.value,
                        wholesalePriceInstance.value,
                        vatAmountInstance.value
                    );
            }
        };
        const addNewPriceListItemButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novu robu",
            onClick: () => openAddNewPriceListItemPopup()
        });
        const addNewSupplierButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novog dobavljača",
            onClick: () => openAddNewSupplierPopup()
        });
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear,
            valueExpr: "id",
            disabled: true
        });
        const qtyOptions = ref({
            value: qty.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updateTotalValue();
            }
        });
        const priceOptions = ref({
            value: price.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updateTotalValue();
            }
        });
        const priceTotalOptions = ref({
            value: priceTotal.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
            onValueChanged: (e) => {
                priceTotal.value = e.value;
            }
        });
        const oibOptions = ref({
            onInitialized: (e) => {
                oibInstance.value = e.component;
            }
        });
        const companyNameOptions = ref({
            onInitialized: (e) => {
                companyNameInstance.value = e.component;
            }
        });
        const zipCodeOptions = ref({
            onInitialized: (e) => {
                zipCodeInstance.value = e.component;
            }
        });
        const cityOptions = ref({
            onInitialized: (e) => {
                cityInstance.value = e.component;
            }
        });
        const streetOptions = ref({
            onInitialized: (e) => {
                streetInstance.value = e.component;
            }
        });
        const countryOptions = ref({
            onInitialized: (e) => {
                countryInstance.value = e.component;
            }
        });
        const emailOptions = ref({
            onInitialized: (e) => {
                emailInstance.value = e.component;
            }
        });
        const receiptFilterInputOptions = ref({
            placeholder: "Pretraži...",
            value: searchValueReceipt.value,
            onValueChanged: (e) => {
                searchValueReceipt.value = e.value;
                eventBus.emit("searchValueReceipt", searchValueReceipt.value);
                tableInstance.value.refresh();
            }
        });

        //instances
        const getReceiptTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getReceiptItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };
        const getReceiptFormInstance = (e) => {
            receiptFormInstance.value = e.component;
        };
        const getAddPopupInstance = (e) => {
            addPopupInstance.value = e.component;
        };
        const getAddFormInstance = (e) => {
            addFormInstance.value = e.component;
        };
        const getAddSupplierPopupInstance = (e) => {
            addSupplierPopupInstance.value = e.component;
        };
        const getAddSupplierFormInstance = (e) => {
            addSupplierFormInstance.value = e.component;
        };
        const getSudregPopupInstance = (e) => {
            sudregPopupInstance.value = e.component;
        };
        const getSudregDataTableInstance = (e) => {
            sudregApiDataTableInstance.value = e.component;
        };
        //receipt table methods
        const onFocusedRowChangedReceipt = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit("newFocusedRowReceipt", selectedRowKey.value);
                }
            } else {
                eventBus.emit("newFocusedRowReceipt", null);
            }
        };
        const addReceipt = () => {
            duplicateReceiptData.value = null;
            tableInstance.value.addRow();
        };
        const duplicateReceipt = (e) => {
            duplicateReceiptData.value = e.row.data;
            tableInstance.value.addRow();
        };
        const editReceipt = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelReceipt = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteReceipt = async (e) => {
            let res = confirm("Jeste li sigurni da želite obrisati primku?");
            res.then(async (c) => {
                if (c) {
                    await handleRowRemovingReceipt(e);
                    tableInstance.value.refresh();
                    // tableInstance.value.deleteRow(e.row.rowIndex);
                }
            });
        };
        const onInitNewRowReceipt = (e) => {
            const now = new Date().toISOString();
            if (duplicateReceiptData.value !== null) {
                e.data = duplicateReceiptData.value;
                priceListItemId.value = null;
                setTimeout(() => {
                    dateSelectInstance.value.option("value", now);
                }, 100);
            } else {
                e.data = {
                    businessYearId: Number(businessYearId.value)
                };
                priceListItemId.value = null;
                tableInstance.value.option("focusedRowIndex", -1);
                receiptItemData.value = [];
                setTimeout(() => {
                    dateSelectInstance.value.option("value", now);
                }, 100);
            }
        };
        const saveNewReceipt = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertingReceipt = (e) => {
            handleRowInsertingReceipt(e, number.value, selectedDate.value);
        };
        const onRowInsertedReceipt = async (e) => {
            await handleRowInsertedReceipt(e);
            qty.value = 1;
            tableInstance.value.refresh();
            addPopupOpened.value = false;
        };
        const onEditingStartReceipt = async (e) => {
            await handleEditingStartReceipt(e);
            const now = new Date().toISOString();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now
                );
                warehouseInstance.value.option("disabled", true);
                dateSelectInstance.value.option("value", e.data.date);
            }, 100);
            priceListItemId.value = null;
        };
        const onEditCanceledReceipt = () => {
            handleEditCanceledReceipt();
            tableInstance.value.refresh();
        };
        const onRowUpdatingReceipt = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            await handleRowUpdatingReceipt(e);
            receiptItemData.value = [];
            qty.value = 1;
        };
        const onRowUpdatedReceipt = () => {
            eventBus.emit("newReceiptItemAdded");
            tableInstance.value.refresh();
        };
        const onRowRemovingReceipt = async (e) => {
            await handleRowRemovingReceipt(e);
        };
        const onExportingReceipt = async () => {
            await handleExportingReceiptRecap(tableInstance.value);
        };
        const exportReceipt = () => {
            let res = confirm("Ispisati primku u pdf formatu?");
            res.then((e) => {
                if (e) eventBus.emit("exportReceipt", selectedRowKey.value);
            });
        };
        //receipt item table methods
        const addReceiptItem = () => {
            itemTableInstance.value.addRow();
            addItemPopupOpened.value = true;
        };
        const editReceiptItem = (e) => {
            itemTableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelReceiptItem = () => {
            handleCancelReceiptItem();
            itemTableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteReceiptItem = (e) => {
            receiptItemData.value.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                rowsToBeDeleted.value.push(e.row.data);
            }
            itemTableInstance.value.refresh();
        };
        const saveNewReceiptItem = () => {
            handleSaveNewReceiptItem(qty.value, price.value, priceTotal.value);
            itemTableInstance.value.saveEditData();
            qty.value = 1;
        };
        const onRowInsertingReceiptItem = (e) => {
            handleRowInsertingReceiptItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                priceListItemId.value
            );
            qty.value = 1;
        };
        const onEditingStartReceiptItem = (e) => {
            qty.value = e.data.qty;
            handleEditingStartReceiptItem(e);
            price.value = e.data.price;
            priceListItemId.value = null;
            setTimeout(() => {
                priceListItemInstance.value.option("disabled", true);
            }, 100);
        };
        const setRowIndexItem = (rowData) => {
            if (!itemTableInstance.value) return "";
            const currentPageIndex = itemTableInstance.value.pageIndex();
            const pageSize = itemTableInstance.value.pageSize();
            const data = itemTableInstance.value.getDataSource().items();
            const rowIndexInPage = data.findIndex((item) => {
                if (item.__KEY__) {
                    return item.__KEY__ === rowData.__KEY__;
                } else if (item.id) {
                    return item.id === rowData.id;
                }
            });

            return currentPageIndex * pageSize + (rowIndexInPage + 1);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const openAddNewPriceListItemPopup = () => {
            addPopupInstance.value.show();
        };
        const saveNewPriceListItem = async () => {
            const now = new Date().toISOString();
            const isValid = addFormInstance.value.validate("addPriceListItem");
            if (isValid.isValid) {
                formData.value.vatId = Number(formData.value.vatId);
                formData.value = {
                    ...formData.value,
                    vat: vatValue.value * 100,
                    createdById: admin.value.id,
                    modifiedById: admin.value.id,
                    createdDate: now,
                    modifiedDate: now,
                    domainId: admin.value.domainId
                };
                try {
                    await priceListItem.value.insert(formData.value);
                    addPopupInstance.value.hide();
                    formData.value = {
                        vatId: 1,
                        hidden: 0,
                        priceCalculationType: "Fiksna cijena"
                    };
                    wholesalePrice.value = 0;
                    retailPrice.value = 0;
                    vatId.value = 1;
                    vatValue.value = 0.25;
                    vatAmount.value = 0;
                } catch (error) {
                    console.error("Error inserting data:", error);
                    const message = error.message;
                    notify(
                        { message, width: 450, closeOnClick: true },
                        "error",
                        10000
                    );
                    return null;
                }
            }
        };
        const cancelNewPriceListItem = () => {
            formData.value = {
                vatId: 1,
                hidden: 0,
                priceCalculationType: "Fiksna cijena"
            };

            wholesalePrice.value = 0;
            retailPrice.value = 0;
            vatId.value = 1;
            vatValue.value = 0.25;
            vatAmount.value = 0;
            addPopupInstance.value.hide();
        };
        const openAddNewSupplierPopup = () => {
            addSupplierPopupInstance.value.show();
        };
        const saveNewSupplier = async () => {
            const now = new Date().toISOString;
            const isValid =
                addSupplierFormInstance.value.validate("addSupplier");
            if (isValid.isValid) {
                formDataSupplier.value = {
                    ...formDataSupplier.value,
                    createdById: admin.value.id,
                    modifiedById: admin.value.id,
                    createdDate: now,
                    modifiedDate: now,
                    domainId: admin.value.domainId
                };
                const res = await supplier.value.insert(formDataSupplier.value);
                if (res) {
                    addSupplierPopupInstance.value.hide();
                    formDataSupplier.value = {};
                }
            }
        };
        const cancelNewSupplier = () => {
            formDataSupplier.value = {};
            addSupplierPopupInstance.value.hide();
        };
        const openSudregPopup = () => {
            sudregPopupInstance.value.show();
        };
        const fetchCompanyData = async (e) => {
            const res = await sudregApiStore.fetchCompanyData({
                tip_identifikatora: "oib",
                identifikator: e.row.data.oib.toString()
            });
            oibInstance.value.option(
                "value",
                res.potpuni_oib ? res.potpuni_oib : res.oib
            );
            companyNameInstance.value.option("value", res.tvrtka.ime);
            cityInstance.value.option(
                "value",
                res.sjediste.naziv_naselja ? res.sjediste.naziv_naselja : ""
            );
            streetInstance.value.option(
                "value",
                res.sjediste.ulica && res.sjediste.kucni_broj
                    ? res.sjediste.ulica + " " + res.sjediste.kucni_broj
                    : ""
            );
            countryInstance.value.option("value", "Hrvatska");
            emailInstance.value.option(
                "value",
                res.email_adrese ? res.email_adrese[0].adresa : ""
            );
            sudregPopupInstance.value.hide();
            eventBus.emit("searchValue", "");
            searchBarInstance.value.option("value", "");
        };

        //element events
        const keyDownEvent = (e) => {
            if (
                e.key === "Insert" &&
                addPopupOpened.value === true &&
                addItemPopupOpened.value === false
            ) {
                if (itemTableInstance.value) addReceiptItem();
            }
        };
        const onAddPopupShown = () => {
            warehouseInstance.value.focus();
            addPopupOpened.value = true;
        };
        const onAddPopupHidden = () => {
            addPopupOpened.value = false;
        };
        const onItemPopupShown = () => {
            addItemPopupOpened.value = true;
        };
        const onItemPopupHidden = () => {
            addItemPopupOpened.value = false;
        };

        //general methods
        const updateTotalValue = () => {
            priceTotal.value = (
                Number(qty.value) * Number(price.value)
            ).toFixed(2);
            priceTotalInstance.value.option("value", Number(priceTotal.value));
        };
        const supplierName = (item) => {
            return `${item.name} ${item.lastname}`;
        };
        const isDeleted = (e) => {
            return e.row.data.status === "deleted" ? true : false;
        };
        const isNotDeleted = (e) => {
            return e.row.data.status === "active" ? true : false;
        };
        //subscribe to events
        eventBus.on("receiptItems", (data) => {
            receiptItemData.value = data;
        });

        return {
            setRowIndexItem,
            vatIdOptions,
            duplicateReceipt,
            isDeleted,
            isNotDeleted,
            domain,
            domainUser,
            customer,
            receipt,
            supplier,
            warehouse,
            receiptItem,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            rowsToBeDeleted,
            businessYearId,
            receiptItemData,
            columnChooserMode,
            priceTotalFormat,
            receiptAddButtonOptions,
            receiptItemAddButtonOptions,
            receiptRefreshButtonOptions,
            receiptExportButtonOptions,
            columnChooserButtonOptions,
            receiptPopupToolbarItems,
            receiptItemPopupToolbarItems,
            dateSelectOptions,
            warehouseStatusOptions,
            numberOptions,
            supplierSelectBoxOptions,
            warehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            businessYearSelectBoxOptions,
            qtyOptions,
            priceOptions,
            priceTotalOptions,
            getReceiptTableInstance,
            getReceiptItemTableInstance,
            getReceiptFormInstance,
            onFocusedRowChangedReceipt,
            editReceipt,
            cancelReceipt,
            deleteReceipt,
            onInitNewRowReceipt,
            saveNewReceipt,
            onRowInsertingReceipt,
            onRowInsertedReceipt,
            onEditingStartReceipt,
            onEditCanceledReceipt,
            onRowUpdatingReceipt,
            onRowUpdatedReceipt,
            onRowRemovingReceipt,
            onExportingReceipt,
            addReceiptItem,
            editReceiptItem,
            cancelReceiptItem,
            deleteReceiptItem,
            saveNewReceiptItem,
            onRowInsertingReceiptItem,
            onEditingStartReceiptItem,
            onAddPopupShown,
            onAddPopupHidden,
            onItemPopupShown,
            onItemPopupHidden,
            supplierName,
            exportReceipt,
            addNewPriceListItemButtonOptions,
            getAddPopupInstance,
            priceCalculationTypeOptions,
            itemTypeOptions,
            hiddenOptions,
            vatAmountOptions,
            wholesalePriceOptions,
            retailPriceOptions,
            addPopupToolbarItems,
            getAddFormInstance,
            formData,
            priceListSelectBoxOptions,
            cancelNewPriceListItem,
            addNewSupplierButtonOptions,
            addSupplierPopupToolbarItems,
            formDataSupplier,
            getAddSupplierPopupInstance,
            getAddSupplierFormInstance,
            cancelNewSupplier,
            oibOptions,
            companyNameOptions,
            zipCodeOptions,
            cityOptions,
            streetOptions,
            countryOptions,
            emailOptions,
            invoiceTypeSelectBoxOptions,
            sudregApi,
            searchBarOptions,
            sudregPopupToolbarItems,
            getSudregPopupInstance,
            fetchCompanyData,
            getSudregDataTableInstance,
            fetchCompanyDataButtonOptions,
            receiptFilterInputOptions
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#receiptTable {
    max-width: 100%;
}
#receiptItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
}
.split {
    border: 0.2px solid #1c1c1c;
    margin: 0.5vh;
    /* width: 84.5vw; */
    max-width: 100vw;
}
</style>
