import { reactive, toRefs } from "vue";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { domain } from "@/stores/domainStore";
import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";
import { poppins } from "@/poppinsbase64";
import { Workbook } from "exceljs";
import { divWrapper } from "@/utils/divWrapper";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { exportDataGrid as exportExcel } from "devextreme/excel_exporter";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
export function useWarehouseItemStatus() {
    const domainUserStore = useDomainUserStore();
    const state = reactive({
        priceListItem: priceListItem,
        warehouse: warehouse,
        warehouseItemStatus: warehouseItemStatus,
        businessYear: businessYear,
        warehouseData: null,
        businessYearData: null,
        warehouseName: null,
        businessYearName: null,
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        documentHeader: null,
        documentFooter: null,
        domainName: null,
        admin: domainUserStore.getDomainUser
    });

    async function fetchWarehouseDataByKey(id) {
        try {
            state.warehouseData = await state.warehouse.byKey(id);
            state.warehouseName = state.warehouseData.name;
        } catch (error) {
            console.error("Error fetching warehouse data by key:", error);
            throw error;
        }
    }
    async function fetchBusinessYearDataByKey(id) {
        try {
            state.businessYearData = await state.businessYear.byKey(id);
            state.businessYearName = state.businessYearData.year;
        } catch (error) {
            console.error("Error fetching business year data by key:", error);
            throw error;
        }
    }
    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        state.domainName = domainData.name;
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    function handleWarehouseItemStatusExporting(e) {
        if (e.format === "pdf") {
            const doc = new jsPDF();
            doc.setFontSize(8);
            doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
            doc.addFont(
                "@/assets/Poppins-Regular.ttf",
                "Poppins-Regular",
                "normal"
            );
            doc.setFont("Poppins-Regular");
            const lastPoint = { x: 0, y: 0 };
            doc.setTextColor(0, 0, 0);
            let rb = 1;
            exportPDF({
                jsPDFDocument: doc,
                component: e.component,
                topLeft: { x: 1, y: 40 },
                repeatHeaders: true,
                onRowExporting: (e) => {
                    e.rowHeight = 10;
                    if (
                        e.rowCells[0].text !== "Rb." &&
                        e.rowCells[1].text !== undefined
                    ) {
                        e.rowCells[0].text = rb;
                        rb += 1;
                    }
                },
                columnWidths: [10, 12, 54, 12, 20, 25, 15, 12, 20],
                customDrawCell({ rect, gridCell, pdfCell }) {
                    if (gridCell.rowType === "header") {
                        pdfCell.font.size = 8;
                    } else if (gridCell.rowType === "data") {
                        pdfCell.font.size = 7;
                    } else if (gridCell.rowType === "totalFooter") {
                        pdfCell.font.style = "normal";
                    }

                    if (lastPoint.x < rect.x + rect.w) {
                        lastPoint.x = rect.x + rect.w;
                    }
                    if (lastPoint.y < rect.y + rect.h) {
                        lastPoint.y = rect.y + rect.h;
                    }
                },
                customizeCell({ gridCell, pdfCell }) {
                    pdfCell.wordWrapEnabled = true;
                    if (gridCell.rowType === "header") {
                        pdfCell.textColor = "#000000";
                        pdfCell.backgroundColor = "#D3D3D3";
                        pdfCell.font.size = 7;
                    } else if (gridCell.rowType === "totalFooter") {
                        pdfCell.font.size = 7;
                    }
                }
            }).then(() => {
                const now = new Date();
                const vrijeme =
                    "Vrijeme izrade: " + now.toLocaleString("hr-HR");
                const header = `Stanje skladišta ${state.businessYearName}`;
                const subHeader = `Skladište: ${state.warehouseName}`;
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();
                doc.setFontSize(8);
                doc.setPage(1);
                doc.text(vrijeme, pageWidth - 15, 50, { align: "right" });
                doc.setFontSize(12);
                doc.text(header, 15, 45);
                doc.setFontSize(8);
                doc.text(subHeader, 15, 50);
                doc.line(15, 52, pageWidth - 15, 52);
                const documentName = `Skladiste-${state.warehouseName}-${state.businessYearName}.pdf`;
                doc.html(state.documentHeader, {
                    x: 15,
                    y: 10,
                    width: 180,
                    margin: [0, 0, 0, 0],
                    windowWidth: 800,
                    callback: (doc) => {
                        doc.html(state.documentFooter, {
                            x: 15,
                            y: pageHeight - 10,
                            width: 180,
                            windowWidth: 800,
                            margin: [0, 0, 0, 0],
                            callback: (doc) => {
                                doc.save(documentName);
                            }
                        });
                    }
                });
            });
        } else if (e.format === "xls") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Skladište");
            exportExcel({
                component: e.component,
                worksheet,
                topLeftCell: { row: 1, column: 1 }
            }).then(() => {
                const now = new Date();
                const vrijeme = now.toLocaleString("hr-HR");
                const headerRow = worksheet.getRow(1);
                // const timeRow = worksheet.getRow(1);
                // const warehouseRow = worksheet.getRow(3);
                const itemColumn = worksheet.getColumn(2);
                const totalColumn = worksheet.getColumn(5);
                totalColumn.width = 25;
                itemColumn.width = 80;
                headerRow.height = 20;
                // worksheet.mergeCells(2, 1, 1, 2);
                // worksheet.mergeCells("C1:F2");
                // headerRow.getCell(
                //     1
                // ).value = `Stanje skladišta ${state.businessYearName}`;
                // headerRow.getCell(1).font = {
                //     name: "Helvetica",
                //     size: 18,
                //     bold: true,
                // };
                // headerRow.getCell(1).alignment = {
                //     horizontal: "left",
                //     vertical: "middle",
                // };
                // timeRow.getCell(3).value = vrijeme;
                // timeRow.getCell(3).font = {
                //     name: "Helvetica",
                //     size: 12,
                //     bold: true,
                // };
                // timeRow.getCell(3).alignment = {
                //     horizontal: "right",
                //     vertical: "middle",
                // };
                // warehouseRow.getCell(
                //     1
                // ).value = `Skladište: ${state.warehouseName}`;
                // warehouseRow.getCell(1).font = {
                //     name: "Helvetica",
                //     size: 10,
                //     bold: true,
                // };
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], {
                            type: "application/octet-stream"
                        }),
                        `Skladiste-${state.warehouseName}-${state.businessYearName}-${vrijeme}.xlsx`
                    );
                });
            });
        }
    }
    return {
        ...toRefs(state),
        fetchWarehouseDataByKey,
        fetchBusinessYearDataByKey,
        handleWarehouseItemStatusExporting,
        getDomainData
    };
}
