<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid"
        id="invoiceItemTable"
        :data-source="invoiceItem"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :show-toolbar="false"
        @initialized="getInvoiceItemTableInstance"
    >
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="invoiceItemTableState"
        />
        <DxToolbar>
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxColumnFixing :enabled="true" />
        <DxColumn
            caption="Rb."
            :width="50"
            alignment="center"
            :calculate-cell-value="setRowIndex"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            :allow-exporting="false"
            data-field="priceListItemId"
            data-type="number"
            caption="Šifra"
            name="codeColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="code"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Naziv"
            name="nameColumn"
            :width="200"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="JMJ"
            name="measureUnitColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="measureUnit"
            />
        </DxColumn>
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Kol."
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="id"
            data-type="number"
            caption="Cijena"
            name="priceColumn"
            :format="priceTotalFormat"
        >
            <DxLookup
                :data-source="invoiceItem"
                value-expr="id"
                display-expr="price"
            />
        </DxColumn>
        <DxColumn
            data-field="id"
            data-type="number"
            caption="Iznos"
            name="priceByQtyColumn"
            :format="priceTotalFormat"
        >
            <DxLookup
                :data-source="invoiceItem"
                value-expr="id"
                display-expr="priceByQty"
            />
        </DxColumn>
        <DxColumn
            data-field="id"
            data-type="number"
            caption="Rabat"
            name="discountAmountColumn"
            :format="priceTotalFormat"
        >
            <DxLookup
                :data-source="invoiceItem"
                value-expr="id"
                display-expr="discountAmount"
            />
        </DxColumn>
        <DxColumn
            data-field="id"
            data-type="number"
            caption="Rab%"
            name="discountPercentageColumn"
            :format="priceTotalFormat"
        >
            <DxLookup
                :data-source="invoiceItem"
                value-expr="id"
                display-expr="discountPercentage"
            />
        </DxColumn>
        <DxColumn
            data-field="id"
            data-type="number"
            caption="Konačan iznos"
            name="priceTotalColumn"
            :format="priceTotalFormat"
        >
            <DxLookup
                :data-source="invoiceItem"
                value-expr="id"
                display-expr="priceTotal"
            />
        </DxColumn>
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
    DxPager,
    DxSearchPanel,
    DxToolbar,
    DxItem
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
// import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { useInvoiceItem } from "@/composables/useInvoiceItem.js";
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted } from "vue";
// import bwipjs from "bwip-js";

export default {
    name: "invoiceItemTableComponent",
    components: {
        DxPager,
        DxColumn,
        DxLookup,
        DxDataGrid,
        DxScrolling,
        DxColumnFixing,
        DxStateStoring,
        DxSearchPanel,
        DxToolbar,
        DxItem
    },
    setup() {
        const {
            customer,
            invoice,
            domain,
            invoiceItem,
            businessYear,
            priceListItem,
            priceTotalFormat,
            getDomainData,
            getInvoiceByKey,
            getUserByKey,
            getBusinessYearByKey,
            handleInvoiceExporting
        } = useInvoiceItem();
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        onMounted(async () => await getDomainData());

        const getInvoiceItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        // const onExporting = (tableInstance, sendEmailFlag) => {
        //     handleInvoiceExporting(tableInstance.value, sendEmailFlag);
        // };
        const setRowIndex = (rowData) => {
            if (!tableInstance.value) return "";
            const currentPageIndex = tableInstance.value.pageIndex();
            const pageSize = tableInstance.value.pageSize();

            // Find row index in current page (works for UI & export)
            const data = tableInstance.value.getDataSource().items();
            const rowIndexInPage = data.findIndex(
                (item) => item.id === rowData.id
            );

            return currentPageIndex * pageSize + (rowIndexInPage + 1);
        };
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };

        eventBus.on("newInvoiceItemAdded", async (key) => {
            await getInvoiceByKey(key);
            await getUserByKey();
            await getBusinessYearByKey();
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowInvoice", async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getInvoiceByKey(data);
                await getUserByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        eventBus.on("exportInvoiceItemToPDF", (sendEmailFlag) => {
            handleInvoiceExporting(tableInstance.value, sendEmailFlag);
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );

        return {
            refreshButtonOptions,
            setRowIndex,
            customer,
            invoice,
            domain,
            invoiceItem,
            businessYear,
            priceListItem,
            priceTotalFormat,
            getInvoiceItemTableInstance,
            shouldShowTheDataGrid
            // onExporting
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#invoiceItemTable {
    width: 100%;
}
</style>
