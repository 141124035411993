<template>
    <!-- <div class="table-container"> -->
    <p class="table-name">Skladište</p>
    <DxDataGrid
        id="warehouseTable"
        :data-source="warehouse"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        :focused-row-enabled="true"
        @initialized="getWarehouseTableInstance"
        @focused-row-changed="onFocusedRowChangedWarehouse"
        @row-inserted="onRowInsertedWarehouse"
        @row-updating="onRowUpdatingWarehouse"
        @row-updated="onRowUpdatedWarehouse"
        @init-new-row="onInitNewRowWarehouse"
        @edit-canceled="onEditCanceledWarehouse"
    >
        <DxPaging :page-size="20" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 30]"
        />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="warehouseTableState"
        />
        <DxToolbar>
            <DxItem
                id="addButton"
                :options="addButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="calculateButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxEditing
            :allow-deleting="true"
            :allow-updating="true"
            :use-icons="true"
            mode="row"
        >
        </DxEditing>
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            :visible="false"
            :allow-editing="false"
        />
        <DxColumn
            data-field="name"
            data-type="string"
            caption="Naziv"
            :allow-hiding="false"
        >
            <DxRequiredRule />
            <DxStringLengthRule :max="120" />
        </DxColumn>
        <DxColumn
            data-field="domainId"
            data-type="number"
            caption="Domena"
            :visible="false"
            :allow-editing="false"
        >
            <DxLookup
                :data-source="domain"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="businessYearId"
            data-type="number"
            caption="God."
            :allow-editing="false"
        >
            <DxLookup
                :data-source="businessYear"
                value-expr="id"
                display-expr="year"
            />
        </DxColumn>
        <DxColumn
            data-field="createdById"
            data-type="number"
            caption="Kreirao"
            :allow-editing="false"
        >
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="createdDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Kreirano"
            :allow-editing="false"
        />
        <DxColumn
            data-field="modifiedById"
            data-type="number"
            caption="Uredio"
            :allow-editing="false"
        >
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Uređeno"
            :allow-editing="false"
        />
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton
                hint="Uredi"
                icon="edit"
                @click="editWarehouse"
                :visible="areEditAndDeleteIconsVisible"
            />
            <DxButton
                hint="Spremi"
                icon="check"
                @click="saveNewWarehouse"
                :visible="areSaveAndCancelIconsVisible"
            />
            <DxButton
                hint="Proračunaj stanje skladišta na današnji dan"
                icon="datausage"
                @click="calculateWarehouseItemStatusForCurrentDay"
                :visible="areEditAndDeleteIconsVisible"
            />
            <DxButton
                hint="Odustani"
                icon="close"
                @click="cancelWarehouse"
                :visible="areSaveAndCancelIconsVisible"
            />
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
    </DxDataGrid>
    <CalculatePopup
        :resize-enabled="true"
        :max-width="1000"
        :drag-outside-boundary="true"
        :toolbarItems="calculatePopupToolbarItems"
        title="Proračunaj stanje skladišta do odabranog datuma"
        @initialized="getCalculatePopupInstance"
    >
        <DxForm
            label-location="top"
            label-mode="floating"
            :col-count="3"
            class="dxFormCalculate"
            @initialized="getFormInstance"
            validationGroup="calculate"
        >
            <DxSimpleItem
                data-type="number"
                editor-type="dxSelectBox"
                :editor-options="warehouseSelectEditorOptions"
                :col-span="1"
            >
                <DxRequiredRule message="Obavezno polje." />
            </DxSimpleItem>
            <DxSimpleItem
                :required="true"
                editor-type="dxDateBox"
                :editor-options="dateEditorOptions"
                :col-span="1"
            >
                <DxRequiredRule message="Obavezno polje." />
            </DxSimpleItem>
            <DxButtonItem :button-options="submitButtonOptions" :col-span="4" />
        </DxForm>

        <DxDataGrid
            id="calculateTable"
            :data-source="warehouseItemStatusCalculation"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :allow-column-resizing="true"
            @initialized="getCalculateDataTableInstance"
        >
            <DxPaging :page-size="20" />
            <DxColumnFixing :enabled="true" />
            <DxPager
                :allowed-page-sizes="[]"
                :visible="true"
                display-mode="adaptive"
                :show-info="true"
                info-text="Stranica {0} od {1} ({2} stavki)"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxToolbar>
                <DxItem
                    :options="exportButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
            </DxToolbar>
            <DxColumn data-field="id" data-type="number" :visible="false" />
            <DxColumn
                caption="Šifra"
                data-field="priceListItemId"
                data-type="number"
                name="codeColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="code"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Naziv"
                name="nameColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                width="120"
                data-field="priceAverage"
                data-type="number"
                caption="Prosj. cijena"
                format="###,###,###,##0.00"
            />
            <DxColumn
                width="120"
                data-field="priceLatest"
                data-type="number"
                caption="Zadnja n. cijena"
                format="###,###,###,##0.00"
            />
            <DxColumn
                width="120"
                data-field="qty"
                data-type="number"
                caption="Kol."
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="JMJ"
                name="jmjColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="measureUnit"
                />
            </DxColumn>
            <DxColumn
                width="120"
                caption="Ukupno"
                :calculate-cell-value="calculateTotal"
                data-type="number"
                format="###,###,###,##0.00"
            />
            <DxSummary>
                <DxTotalItem
                    column="Ukupno"
                    summary-type="sum"
                    :value-format="priceTotalFormat"
                    display-format="{0}"
                />
            </DxSummary>
        </DxDataGrid>
    </CalculatePopup>
    <DxLoadPanel
        :position="position"
        :visible="loadingVisible"
        :show-indicator="true"
        message="Računanje..."
    />
    <!-- </div> -->
    <div class="split"></div>
</template>
<script>
//DevExpress
import {
    DxItem,
    DxPager,
    DxButton,
    DxLookup,
    DxColumn,
    DxSummary,
    DxPaging,
    DxToolbar,
    DxEditing,
    DxDataGrid,
    DxScrolling,
    DxTotalItem,
    DxSearchPanel,
    DxRequiredRule,
    DxHeaderFilter,
    DxStateStoring,
    DxColumnFixing,
    DxColumnChooser,
    DxStringLengthRule,
    DxColumnChooserSelection
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { DxPopup as CalculatePopup } from "devextreme-vue/popup";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import {
    DxForm,
    // DxEmptyItem,
    DxSimpleItem,
    DxButtonItem
} from "devextreme-vue/form";

//Composables
import { useWarehouse } from "@/composables/useWarehouse.js";

//Utils
import { ref, onMounted } from "vue";
import eventBus from "../../eventBus.js";

export default {
    name: "warehouseTableComponent",
    components: {
        DxItem,
        DxForm,
        DxPager,
        DxButton,
        DxLookup,
        DxColumn,
        DxPaging,
        DxToolbar,
        DxEditing,
        DxSummary,
        DxDataGrid,
        DxScrolling,
        DxTotalItem,
        // DxEmptyItem,
        DxSimpleItem,
        DxButtonItem,
        DxSearchPanel,
        CalculatePopup,
        DxColumnFixing,
        DxRequiredRule,
        DxHeaderFilter,
        DxStateStoring,
        DxColumnChooser,
        DxStringLengthRule,
        DxColumnChooserSelection,
        DxLoadPanel
    },
    setup() {
        //Instances
        const formInstance = ref(null);
        const tableInstance = ref(null);
        const calculateTableInstance = ref(null);
        const calculatePopupInstance = ref(null);
        const dateSelectInstance = ref(null);

        //Data
        const selectedRowKey = ref(null);
        const selectedDate = ref(null);
        const selectedWarehouse = ref(null);
        const position = { of: "#warehouseTable" };
        const loadingVisible = ref(false);
        const {
            admin,
            domain,
            warehouse,
            domainUser,
            businessYear,
            getDomainData,
            priceListItem,
            handleRowInit,
            businessYearId,
            priceTotalFormat,
            handleRowUpdating,
            columnChooserMode,
            warehouseItemStatusCalculation,
            handleExportCalculatedWarehouseItemStatus,
            handleRowInsertedWarehouse,
            handleRowUpdatedWarehouse
        } = useWarehouse();
        onMounted(async () => {
            await getDomainData();
        });

        //popup toolbar items
        const calculatePopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => calculatePopupInstance.value.hide()
                }
            }
        ]);

        //Button options
        const addButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addWarehouse();
            }
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        const exportButtonOptions = ref({
            icon: "export",
            onClick: () => {
                exportCalculatedWarehouseItemStatus();
            }
        });
        const calculateButtonOptions = ref({
            icon: "dataarea",
            hint: "Izračun stanja skladišta do odabranog datuma",
            onClick: () => {
                openCalculateStatus();
            }
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            }
        });
        const submitButtonOptions = ref({
            text: "Proračunaj",
            useSubmitBehavior: true,
            stylingMode: "contained",
            type: "default",
            width: "100%",
            alignment: "center",
            onClick: () => {
                calculate();
            }
        });

        //editor options
        const warehouseSelectEditorOptions = ref({
            dataSource: warehouse.value,
            valueExpr: "id",
            displayExpr: "name",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onValueChanged: (e) => {
                selectedWarehouse.value = e.value;
            }
        });
        const dateEditorOptions = ref({
            onInitialized: (e) => {
                const now = new Date();
                dateSelectInstance.value = e.component;
                dateSelectInstance.value.option("value", now);
                selectedDate.value = now;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            }
        });

        //instances
        const getWarehouseTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getCalculatePopupInstance = (e) => {
            calculatePopupInstance.value = e.component;
        };
        const getCalculateDataTableInstance = (e) => {
            calculateTableInstance.value = e.component;
        };
        const getFormInstance = (e) => {
            formInstance.value = e.component;
        };

        //warehouse table methods
        const onFocusedRowChangedWarehouse = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                eventBus.emit("newFocusedRowWarehouse", selectedRowKey.value);
            } else {
                eventBus.emit("newFocusedRowWarehouse", null);
            }
        };
        const addWarehouse = () => {
            tableInstance.value.addRow();
        };
        const editWarehouse = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelWarehouse = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteWarehouse = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowWarehouse = (e) => {
            tableInstance.value.option("focusedRowIndex", -1);
            handleRowInit(e);
        };
        const saveNewWarehouse = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertedWarehouse = async (e) => {
            await handleRowInsertedWarehouse(e);
        };
        const onEditCanceledWarehouse = () => {
            tableInstance.value.refresh();
        };
        const onRowUpdatingWarehouse = (e) => {
            handleRowUpdating(e);
        };
        const onRowUpdatedWarehouse = async (e) => {
            await handleRowUpdatedWarehouse(e);
        };

        //Button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const openCalculateStatus = () => {
            calculatePopupInstance.value.show();
        };
        const calculate = async () => {
            const isValid = formInstance.value.validate("calculate");
            if (isValid.isValid) {
                eventBus.emit("calculateWarehouseItemStatus", {
                    domainId: admin.value.domainId,
                    businessYearId: businessYearId.value,
                    warehouseId: selectedWarehouse.value,
                    createdDate: adjustToLocalMidnight(
                        selectedDate.value.toISOString()
                    )
                });
                calculateTableInstance.value.refresh();
            }
        };
        const exportCalculatedWarehouseItemStatus = async () => {
            await handleExportCalculatedWarehouseItemStatus(
                calculateTableInstance.value,
                selectedDate.value.toISOString(),
                selectedWarehouse.value
            );
        };
        const calculateWarehouseItemStatusForCurrentDay = async (e) => {
            loadingVisible.value = true;
            const res = await warehouseItemStatusCalculation.value.update({
                warehouseId: e.row.data.id,
                businessYearId: e.row.data.businessYearId,
                domainId: e.row.data.domainId
            });
            if (res.status === 200) {
                loadingVisible.value = false;
                eventBus.emit("warehouseCalculationSuccess");
            }
        };

        //general functions
        const adjustToLocalMidnight = (selectedDate) => {
            const date = new Date(selectedDate);
            date.setHours(date.getHours() + 25);
            return date.toISOString();
        };
        const calculateTotal = (rowData) => {
            if (rowData) {
                return rowData.priceLatest * rowData.qty;
            }
        };
        const areEditAndDeleteIconsVisible = (e) => {
            return !e.row.isEditing ? true : false;
        };
        const areSaveAndCancelIconsVisible = (e) => {
            return e.row.isEditing ? true : false;
        };

        return {
            domain,
            position,
            warehouse,
            domainUser,
            businessYear,
            editWarehouse,
            priceListItem,
            loadingVisible,
            calculateTotal,
            deleteWarehouse,
            cancelWarehouse,
            getFormInstance,
            saveNewWarehouse,
            priceTotalFormat,
            addButtonOptions,
            columnChooserMode,
            dateEditorOptions,
            submitButtonOptions,
            exportButtonOptions,
            refreshButtonOptions,
            onInitNewRowWarehouse,
            calculateButtonOptions,
            onRowUpdatingWarehouse,
            onEditCanceledWarehouse,
            getWarehouseTableInstance,
            getCalculatePopupInstance,
            columnChooserButtonOptions,
            calculatePopupToolbarItems,
            areEditAndDeleteIconsVisible,
            areSaveAndCancelIconsVisible,
            onFocusedRowChangedWarehouse,
            warehouseSelectEditorOptions,
            getCalculateDataTableInstance,
            warehouseItemStatusCalculation,
            calculateWarehouseItemStatusForCurrentDay,
            onRowInsertedWarehouse,
            onRowUpdatedWarehouse
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#warehouseTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
.dxFormCalculate {
    margin-bottom: 3vh;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
}
.split {
    border: 0.2px solid #1c1c1c;
    margin: 0.5vh;
    /* width: 84.5vw; */
    max-width: 100vw;
}
</style>
