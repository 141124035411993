import { defineStore } from "pinia";
import config from "../../config.json";
import eventBus from "../../eventBus";

export const useDomainUserStore = defineStore("domainUserStore", {
    state: () => ({ user: null }),
    getters: {
        getDomainUser: (state) => state.user
    },
    actions: {
        async authenticateUser() {
            try {
                const res = await fetch(`${config.API_URL}/auth`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.ok) {
                    this.user = await res.json();
                    if (this.user !== true) {
                        eventBus.emit("newUserLoggedIn", {
                            userDomainId: this.user.domainId
                            // businessYearId: Number(
                            //     localStorage.getItem("businessYearId")
                            // )
                        });
                        // eventBus.emit(
                        //     "newUserLoggedIn",

                        //     this.user.domainId
                        // );
                        return true;
                    } else {
                        this.user = null;
                        eventBus.emit("newUserLoggedIn", null);
                        return false;
                    }
                } else {
                    this.user = null;
                    eventBus.emit("newUserLoggedIn", null);
                    return false;
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                this.user = null;
            }
        },
        async loginDomainUser(userCredentials) {
            const request = {};
            request.method = "POST";
            request.headers = {
                "Content-Type": "application/json"
            };
            request.body = JSON.stringify(userCredentials);
            request.credentials = "include";
            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.USERS}/${config.PATHS.LOGIN}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = await response.json();
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Error logging in: ", error);
                throw error;
            }
        },
        async logout() {
            const request = {};
            request.method = "POST";
            request.headers = {
                "Content-Type": "application/json"
            };
            request.credentials = "include";
            try {
                await fetch(
                    `${config.API_URL}/${config.PATHS.USERS}/${config.PATHS.LOGOUT}`,
                    request
                );
            } catch (error) {
                console.error("Error logging out: ", error);
                throw error;
            }
        },
        async registerNewDomain(requestData) {
            const request = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            };

            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.REGISTER_DOMAIN}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = response;
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Network error:", error);
                return {
                    success: false,
                    message:
                        "Nije moguće povezati se. Prijavite administratoru.",
                    error: error.message || error
                };
            }
        },
        async forgotPassword(requestData) {
            const request = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            };

            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.FORGOT_PASSWORD}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = response;
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Network error:", error);
                return {
                    success: false,
                    message:
                        "Nije moguće povezati se. Prijavite administratoru.",
                    error: error.message || error
                };
            }
        },
        async setNewPassword(requestData) {
            const request = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            };

            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.SET_NEW_PASSWORD}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = response;
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Network error:", error);
                return {
                    success: false,
                    message:
                        "Nije moguće povezati se. Prijavite administratoru.",
                    error: error.message || error
                };
            }
        },
        async registerNewDomainUser(requestData) {
            const request = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            };

            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.REGISTER_NEW_DOMAIN_USER}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = response;
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Network error:", error);
                return {
                    success: false,
                    message:
                        "Nije moguće povezati se. Prijavite administratoru.",
                    error: error.message || error
                };
            }
        }
    },
    setup() {
        this.$subscribe((mutation, state) => {
            if (mutation.events.key === "user") {
                console.log("User state changed:", state.user);
            }
        });
    }
});
