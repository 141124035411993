import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const changesLog = new CustomStore({
    key: "id",
    insert: async (values) => {
        try {
            const response = await sendRequest(
                `${config.API_URL}/${config.PATHS.CHANGES_LOG}`,
                "POST",
                {
                    values: values,
                }
            );
            return response;
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
});
