import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore";
import { supplier } from "@/stores/supplierStore";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { divWrapper } from "@/utils/divWrapper";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { sudregApi } from "@/stores/sudregApiStore";
import { changesLog } from "@/stores/changesLogStore";
export function useSupplier() {
    const domainUserStore = useDomainUserStore();
    const invoiceTypes = [{ value: "R1" }, { value: "R2" }];
    const state = reactive({
        domainUser,
        supplier,
        sudregApi,
        saveLastData: false,
        lastSavedData: {},
        columnChooserMode: "select",
        oldDataLog: null,
        domainName: null,
        documentHeader: null,
        documentFooter: null,
        invoiceTypeSelectBoxOptions: {
            items: invoiceTypes,
            value: "R1",
            displayExpr: "value",
            valueExpr: "value",
            placeholder: "Izaberi tip računa"
        },
        admin: domainUserStore.getDomainUser
    });
    function handleNewRowInitSupplier(e) {
        if (state.saveLastData === true) {
            e.data = {
                ...state.lastSavedData
            };
            state.saveLastData = false;
        }
    }
    function handleRowInsertingSupplier(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: state.admin.domainId
        };
        if (state.saveLastData) {
            state.lastSavedData = {
                ...e.data
            };
        }
        return e.data;
    }
    async function handleRowInsertedSupplier(e) {
        const logData = [{ supplier: e.data }];
        const changesLogData = {
            actionType: "INSERT",
            documentType: "supplier",
            oldValue: null,
            newValue: JSON.stringify(logData),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
    }

    async function handleRowUpdatingSupplier(e) {
        const oldData = await supplier.byKey(e.key);
        state.oldDataLog = [{ supplier: oldData }];
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: state.admin.id,
            modifiedDate: now.toISOString()
        };
        return e.newData;
    }
    async function handleRowUpdatedSupplier(e) {
        const newData = await supplier.byKey(e.key);
        const newDataLog = [{ supplier: newData }];
        const changesLogData = {
            actionType: "UPDATE",
            documentType: "supplier",
            oldValue: JSON.stringify(state.oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
        state.oldDataLog = null;
    }
    async function handleRowRemovingSupplier(e) {
        const oldDataLog = [{ supplier: e.row.data }];
        const changesLogData = {
            actionType: "DELETE",
            documentType: "supplier",
            oldValue: JSON.stringify(oldDataLog),
            newValue: null,
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
    }
    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        if (domainData !== null) state.domainName = domainData.name;
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    function handleExportSupplier(table) {
        const doc = new jsPDF();
        doc.setFontSize(8);
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            topLeft: { x: 1, y: 37 },
            repeatHeaders: true,
            onRowExporting: (e) => {
                e.rowHeight = 10;
            },
            columnWidths: [100, 40, 40],
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            }
        }).then(() => {
            const now = new Date();
            const vrijeme = "Vrijeme izrade: " + now.toLocaleString("hr-HR");
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(14);
            doc.setPage(1);
            doc.text("Dobavljači", 15, 45);
            doc.setFontSize(8);
            doc.text(vrijeme, pageWidth - 15, 45, { align: "right" });
            doc.line(15, 47, pageWidth - 15, 47);
            const documentName = `Lista-Dobavljača-${state.domainName}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        }
                    });
                }
            });
        });
    }
    return {
        ...toRefs(state),
        handleNewRowInitSupplier,
        handleRowInsertingSupplier,
        handleRowUpdatingSupplier,
        handleRowInsertedSupplier,
        handleRowUpdatedSupplier,
        handleRowRemovingSupplier,
        getDomainData,
        handleExportSupplier
    };
}
