<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid"
        id="intermediateWarehouseItemTable"
        :data-source="intermediateWarehouseItem"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        @initialized="getIntermediateWarehouseItemTableInstance"
    >
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="intermediateWarehouseItemTableState"
        />
        <DxToolbar>
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxColumnFixing :enabled="true" />
        <DxColumn
            caption="Rb."
            :width="50"
            alignment="center"
            :calculate-cell-value="setRowIndex"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            caption="Šifra"
            data-field="priceListItemId"
            data-type="number"
            name="codeColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="code"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Naziv"
            name="nameColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            width="120"
            data-field="price"
            data-type="number"
            caption="Cijena"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Količina"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="JMJ"
            name="jmjColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="measureUnit"
            />
        </DxColumn>
        <DxColumn
            width="120"
            data-field="priceTotal"
            data-type="number"
            format="###,###,###,##0.00"
            caption="Ukupno"
        />
        <DxSummary>
            <DxTotalItem
                column="priceTotal"
                summary-type="sum"
                :value-format="priceTotalFormat"
                display-format="{0}"
            />
        </DxSummary>
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxPager,
    DxDataGrid,
    DxColumn,
    DxItem,
    DxToolbar,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
    DxSummary,
    DxTotalItem,
    DxSearchPanel
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { useIntermediateWarehouseItem } from "@/composables/useIntermediateWarehouseItem.js";
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted } from "vue";

export default {
    name: "intermediateWarehouseItemTableComponent",
    components: {
        DxPager,
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxLookup,
        DxStateStoring,
        DxSummary,
        DxTotalItem,
        DxItem,
        DxToolbar,
        DxSearchPanel
    },
    setup() {
        const {
            priceListItem,
            businessYear,
            warehouse,
            intermediateWarehouse,
            intermediateWarehouseItem,
            priceTotalFormat,
            handleIntermediateWarehouseExporting,
            getIntermediateWarehouseDataByKey,
            getFromWarehouseByKey,
            getToWarehouseByKey,
            getBusinessYearByKey,
            getDomainData
        } = useIntermediateWarehouseItem();
        onMounted(async () => {
            await getDomainData();
        });
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        const getIntermediateWarehouseItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        const setRowIndex = (rowData) => {
            if (!tableInstance.value) return "";
            const currentPageIndex = tableInstance.value.pageIndex();
            const pageSize = tableInstance.value.pageSize();

            // Find row index in current page (works for UI & export)
            const data = tableInstance.value.getDataSource().items();
            const rowIndexInPage = data.findIndex(
                (item) => item.id === rowData.id
            );

            return currentPageIndex * pageSize + (rowIndexInPage + 1);
        };
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        eventBus.on(
            "exportIntermediateWarehouse",
            (intermediateWarehouseId) => {
                handleIntermediateWarehouseExporting(
                    tableInstance.value,
                    intermediateWarehouseId
                );
            }
        );
        eventBus.on("newIntermediateWarehouseItemAdded", () => {
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowIntermediateWarehouse", async (data) => {
            if (data !== null) {
                await getIntermediateWarehouseDataByKey(data);
                await getFromWarehouseByKey();
                await getToWarehouseByKey();
                await getBusinessYearByKey();
                focusedId.value = true;
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        return {
            refreshButtonOptions,
            setRowIndex,
            priceListItem,
            businessYear,
            warehouse,
            intermediateWarehouse,
            intermediateWarehouseItem,
            priceTotalFormat,
            getIntermediateWarehouseItemTableInstance,
            shouldShowTheDataGrid
        };
    }
    // data() {
    //     return {
    //         //Stores
    //         warehouse: warehouse,
    //         businessYear: businessYear,
    //         priceListItem: priceListItem,
    //         intermediateWarehouse: intermediateWarehouse,
    //         intermediateWarehouseItem: intermediateWarehouseItem,

    //         //Instances
    //         intermediateWarehouseItemTableInstance: null,

    //         //data
    //         toWarehouseData: null,
    //         businessYearData: null,
    //         fromWarehouseData: null,
    //         intermediateWarehouseData: null,

    //         //general data
    //         focusedId: false,
    //         shouldShowTheDataGrid: true,

    //         //utils
    //         poppins: poppins,
    //         priceTotalFormat: {
    //             type: "fixedPoint",
    //             precision: 2,
    //         },
    //     };
    // },
    // created() {
    //     this.subscribeToNewItemEvent();
    //     this.subscribeToNewFocusedRow();
    // },
    // watch: {
    //     focusedId() {
    //         this.shouldShowTheDataGrid = this.focusedId ? true : false;
    //     },
    // },
    // methods: {
    //     //Instances
    //     getIntermediateWarehouseItemTableInstance(e) {
    //         this.intermediateWarehouseItemTableInstance = e.component;
    //         if (!this.focusedId) this.shouldShowTheDataGrid = false;
    //     },

    //     //intermediate warehouse
    //     onExporting(e) {
    //         const doc = new jsPDF();
    //         doc.setFontSize(8);
    //         doc.addFileToVFS("@/assets/Poppins-Regular.ttf", this.poppins);
    //         doc.addFont(
    //             "@/assets/Poppins-Regular.ttf",
    //             "Poppins-Regular",
    //             "normal"
    //         );
    //         doc.setFont("Poppins-Regular");
    //         const lastPoint = { x: 0, y: 0 };
    //         doc.setTextColor(0, 0, 0);
    //         exportPDF({
    //             jsPDFDocument: doc,
    //             component: e.component,
    //             topLeft: { x: 1, y: 25 },
    //             repeatHeaders: true,
    //             onRowExporting: (e) => {
    //                 e.rowHeight = 6;
    //             },
    //             columnWidths: [12, 96, 20, 15, 12, 25],
    //             customDrawCell({ rect, gridCell, pdfCell }) {
    //                 if (gridCell.rowType === "header") {
    //                     pdfCell.font.size = 8;
    //                 } else if (gridCell.rowType === "data") {
    //                     pdfCell.font.size = 7;
    //                 } else if (gridCell.rowType === "totalFooter") {
    //                     pdfCell.font.style = "normal";
    //                 }

    //                 if (lastPoint.x < rect.x + rect.w) {
    //                     lastPoint.x = rect.x + rect.w;
    //                 }
    //                 if (lastPoint.y < rect.y + rect.h) {
    //                     lastPoint.y = rect.y + rect.h;
    //                 }
    //             },
    //             customizeCell({ gridCell, pdfCell }) {
    //                 pdfCell.wordWrapEnabled = true;
    //                 if (gridCell.rowType === "header") {
    //                     pdfCell.textColor = "#000000";
    //                     pdfCell.backgroundColor = "#D3D3D3";
    //                     pdfCell.font.size = 7;
    //                 } else if (gridCell.rowType === "totalFooter") {
    //                     pdfCell.font.size = 7;
    //                 }
    //             },
    //         }).then(() => {
    //             const now = new Date();
    //             const vrijeme =
    //                 "Vrijeme izrade: " + now.toLocaleString("hr-HR");
    //             const company = `GT-Net`;
    //             const header = `Međuskladišnica ${this.intermediateWarehouseData.number}/${this.businessYearData.year}`;
    //             const fromWarehouse = `Iz skladišta: ${this.fromWarehouseData.name}`;
    //             const toWarehouse = `U skladište: ${this.toWarehouseData.name}`;
    //             const footerReceiver = "Potpis primatelja: ";
    //             const footerSender = "Potpis pošiljatelja: ";
    //             const pageWidth = doc.internal.pageSize.getWidth();
    //             doc.setFontSize(8);
    //             doc.setPage(1);
    //             doc.text(vrijeme, pageWidth - 15, 33, { align: "right" });
    //             doc.setFontSize(12);
    //             doc.text(company, 15, 15);
    //             doc.text(header, 15, 22);
    //             doc.setFontSize(8);
    //             doc.text(fromWarehouse, 15, 28);
    //             doc.text(toWarehouse, 15, 33);
    //             doc.line(15, 35, pageWidth - 15, 35);
    //             doc.text(footerReceiver, 196 - 15, 285 - 15, {
    //                 align: "right",
    //             });
    //             doc.text(footerSender, 25, 285 - 15, {
    //                 align: "left",
    //             });
    //             doc.line(lastPoint.x, 285, lastPoint.x - 58, 285);
    //             doc.line(10, 285, 68, 285);
    //             doc.save(
    //                 `Medjuskladisnica-${this.intermediateWarehouseData.number}-${this.businessYearData.year}.pdf`
    //             );
    //         });
    //     },
    //     subscribeToNewItemEvent() {
    //         eventBus.on("newIntermediateWarehouseItemAdded", () => {
    //             this.intermediateWarehouseItemTableInstance.refresh();
    //         });
    //     },
    //     subscribeToNewFocusedRow() {
    //         eventBus.on("newFocusedRowIntermediateWarehouse", async (data) => {
    //             if (data !== null) {
    //                 this.intermediateWarehouseData =
    //                     await this.intermediateWarehouse.byKey(data);
    //                 this.fromWarehouseData = await this.warehouse.byKey(
    //                     this.intermediateWarehouseData.fromWarehouseId
    //                 );
    //                 this.toWarehouseData = await this.warehouse.byKey(
    //                     this.intermediateWarehouseData.toWarehouseId
    //                 );
    //                 this.businessYearData = await this.businessYear.byKey(
    //                     this.intermediateWarehouseData.businessYearId
    //                 );
    //                 this.focusedId = true;
    //             } else {
    //                 this.focusedId = false;
    //             }
    //             this.intermediateWarehouseItemTableInstance.refresh();
    //         });
    //     },
    // },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#intermediateWarehouseItem {
    width: 100%;
}
</style>
