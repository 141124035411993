import { createRouter, createWebHashHistory } from "vue-router";

import loginForm from "@/views/loginView";
import generalView from "@/views/generalView";
import registerDomainView from "@/views/registerDomainView.vue";
import activationStatus from "@/views/activationStatusView.vue";
import forgottenPasswordView from "@/views/forgottenPasswordView.vue";
import setNewPasswordView from "@/views/setNewPasswordView.vue";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import eventBus from "../../eventBus.js";

const routes = [
    {
        path: "/login",
        component: loginForm,
        name: "login",
        props: (route) => ({
            expired: route.query.expired
        })
    },
    { path: "/home", component: generalView, name: "home" },
    {
        path: "/register-domain",
        component: registerDomainView,
        name: "register-domain"
    },
    {
        path: "/forgot-password",
        component: forgottenPasswordView,
        name: "forgot-password"
    },
    {
        path: "/receipt",
        component: generalView,
        name: "Primka"
    },
    {
        path: "/invoice",
        component: generalView,
        name: "Račun"
    },
    {
        path: "/offer",
        component: generalView,
        name: "Ponuda"
    },
    {
        path: "/initial-state",
        component: generalView,
        name: "Početno stanje"
    },
    {
        path: "/dispatch-note",
        component: generalView,
        name: "Otpremnica"
    },
    {
        path: "/intermediate-warehouse",
        component: generalView,
        name: "Međuskladišnica"
    },
    {
        path: "/warehouse",
        component: generalView,
        name: "Skladište"
    },
    {
        path: "/price-list",
        component: generalView,
        name: "Cijenik"
    },
    {
        path: "/supplier",
        component: generalView,
        name: "Dobavljači"
    },
    {
        path: "/customer",
        component: generalView,
        name: "Kupci"
    },
    {
        path: "/settings",
        component: generalView,
        name: "Postavke"
    },
    {
        path: "/invoice-outgoing",
        component: generalView,
        name: "Izlazni"
    },
    {
        path: "/activation-status",
        name: "activation-status",
        component: activationStatus,
        props: (route) => ({
            error: route.query.error,
            success: route.query.success
        })
    },
    {
        path: "/set-new-password",
        name: "set-new-password",
        component: setNewPasswordView,
        props: (route) => ({
            error: route.query.error,
            token: route.query.token
        })
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach(async (to, _from, next) => {
    const tables = [
        "Primka",
        "Račun",
        "Ponuda",
        "Početno stanje",
        "Otpremnica",
        "Međuskladišnica",
        "Skladište",
        "Cijenik",
        "Dobavljači",
        "Kupci",
        "Postavke",
        "Izlazni",
        ""
    ];
    const domainUserStore = useDomainUserStore();
    let isUserLoggedIn = false;
    try {
        const response = await domainUserStore.authenticateUser();
        if (response) {
            isUserLoggedIn = true;
        }
    } catch {
        isUserLoggedIn = false;
    }
    if (tables.includes(to.name) && isUserLoggedIn) {
        eventBus.emit("tableToRender", to.name);
    }

    if (to.name === "set-new-password") {
        return next();
    }
    if (to.name === "activation-status" && _from.fullPath !== "/login") {
        if (_from.fullPath !== "/home") {
            return next();
        } else {
            return next({ name: "home" });
        }
    }

    if (isUserLoggedIn) {
        if (to.name === "login" || to.path === "/") {
            return next({ name: "home" });
        }
        return next();
    }

    if (!isUserLoggedIn) {
        if (["login", "register-domain", "forgot-password"].includes(to.name)) {
            return next();
        } else return next({ name: "login" });
    }

    return next();
});

export default router;
