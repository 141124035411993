<template>
    <!-- <div class="table-container"> -->
    <p class="table-name">Izlazni e-Račun</p>
    <DxDataGrid
        id="eInvoiceOutgoingTable"
        :data-source="eInvoiceOutgoing"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :focused-row-enabled="true"
        :row-alternation-enabled="true"
        :allow-column-resizing="true"
        @initialized="getEInvoiceOutgoingTableInstance"
        @focused-row-changed="onFocusedRowChangedEInvoiceOutgoing"
    >
        <DxPaging :page-size="20" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 30]"
        />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="eInvoiceOutgoingTableState"
        />
        <DxToolbar>
            <DxItem
                :options="eInvoiceOutgoingRefreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
        </DxToolbar>
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            :visible="false"
        />
        <DxColumn
            data-field="number"
            data-type="number"
            caption="Broj"
            :allow-hiding="false"
        />
        <DxColumn data-field="businessYearId" data-type="number" caption="God.">
            <DxLookup
                :data-source="businessYear"
                value-expr="id"
                display-expr="year"
            />
        </DxColumn>
        <DxColumn
            data-field="warehouseId"
            data-type="number"
            caption="Skladište"
        >
            <DxLookup
                :data-source="warehouse"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="date"
            data-type="date"
            caption="Datum"
            format="dd/MM/yyyy"
        />
        <DxColumn data-field="userId" data-type="number" caption="Kupac">
            <DxLookup
                :data-source="dataSource"
                value-expr="id"
                :displayExpr="userName"
            />
        </DxColumn>
        <DxColumn data-field="remark" data-type="string" caption="Opis" />
        <DxColumn
            data-field="invoiceId"
            data-type="string"
            caption="Broj računa"
        >
            <!-- <DxLookup
                    :data-source="invoice"
                    value-expr="id"
                    display-expr="number"
                /> -->
        </DxColumn>
        <DxColumn
            data-field="priceTotal"
            data-type="number"
            caption="Ukupno"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="status"
            data-type="string"
            caption="Status"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn data-field="createdById" data-type="number" caption="Kreirao">
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="createdDate"
            data-type="datetime"
            caption="Kreirano"
            format="dd/MM/yyyy HH:mm"
        />
        <DxColumn data-field="modifiedById" data-type="number" caption="Uredio">
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            caption="Uređeno"
            format="dd/MM/yyyy HH:mm"
        />

        <DxColumn
            data-field="domainId"
            data-type="number"
            caption="Domena"
            :visible="false"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        >
            <DxLookup
                :data-source="domain"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton
                hint="Check the status"
                icon="refresh"
                @click="checkEInvoiceStatus"
            />
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
    </DxDataGrid>
    <!-- </div> -->
    <div class="split"></div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxButton,
    DxToolbar,
    // DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import eventBus from "../../eventBus.js";
import { ref } from "vue";

export default {
    name: "eInvoiceOutgoingTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxButton,
        DxToolbar,
        DxHeaderFilter,
        DxLookup,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager
    },
    setup() {
        //table instances
        const tableInstance = ref(null);

        //general data
        const selectedRowKey = ref(null);

        //button item options
        const eInvoiceOutgoingRefreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            }
        });

        //instances
        const getEInvoiceOutgoingTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        //dispatch note table methods
        const onFocusedRowChangedEInvoiceOutgoing = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit(
                        "newFocusedRowEInvoiceOutgoing",
                        selectedRowKey.value
                    );
                }
            } else {
                eventBus.emit("newFocusedRowEInvoiceOutgoing", null);
            }
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };

        //General methods
        const userName = (item) => {
            return item.userType === "business"
                ? `${item.id} | ${item.companyName}, ${item.name} ${item.lastname}`
                : item.name || item.lastname
                ? `${item.id} | ${item.name} ${item.lastname}`
                : `${item.id} | ${item.sign}`;
        };

        return {
            eInvoiceOutgoingRefreshButtonOptions,
            columnChooserButtonOptions,
            getEInvoiceOutgoingTableInstance,
            onFocusedRowChangedEInvoiceOutgoing,
            userName
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#eInvoiceOutgoingTable {
    max-width: 100%;
}
#dispatchNoteItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
}
.split {
    border: 0.2px solid #1c1c1c;
    margin: 0.5vh;
    /* width: 84.5vw; */
    max-width: 100vw;
}
</style>
