import { reactive, toRefs } from "vue";
import { user } from "@/stores/userStore.js";
import { customer } from "@/stores/customerStore.js";
import { invoice } from "@/stores/invoiceStore.js";
import { invoiceItem } from "@/stores/invoiceItemStore.js";
import { domain } from "@/stores/domainStore.js";
import { businessYear } from "@/stores/businessYearStore.js";
import { priceListItem } from "@/stores/priceListItemStore";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { poppinsBold } from "@/poppinsboldbase64.js";
import eventBus from "../../eventBus.js";
import { generateBarcode } from "@/utils/generateBarcode.js";
import { getCurrentDateTime } from "@/utils/getCurrentDateTime.js";
import { divWrapper } from "@/utils/divWrapper.js";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
export function useInvoiceItem() {
    const domainUserStore = useDomainUserStore();
    const state = reactive({
        user,
        customer,
        invoice,
        domain,
        invoiceItem,
        businessYear,
        priceListItem,
        userData: null,
        invoiceData: null,
        businessYearData: null,
        documentHeader: null,
        documentFooter: null,
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        admin: domainUserStore.getDomainUser
    });

    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }

    async function getInvoiceByKey(id) {
        state.invoiceData = await invoice.byKey(id);
    }
    async function getUserByKey() {
        state.userData =
            state.admin.domainId === 1
                ? await user.byKey(state.invoiceData.userId)
                : await customer.byKey(state.invoiceData.userId);
    }
    async function getBusinessYearByKey() {
        state.businessYearData = await businessYear.byKey(
            state.invoiceData.businessYearId
        );
    }

    function handleInvoiceExporting(table, sendEmailFlag) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFileToVFS("@/assets/Poppins-Bold.ttf", poppinsBold);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.addFont("@/assets/Poppins-Bold.ttf", "Poppins-Bold", "bold");
        doc.setFont("Poppins-Regular");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        let rb = 1;
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            repeatHeaders: true,
            topLeft: { x: 1, y: 85 },
            columnWidths: [10, 40, 13, 12, 22, 22, 22, 17, 22],
            onRowExporting: (e) => {
                e.rowHeight = 15;
                if (e.rowCells[0].text !== "Rb.") {
                    e.rowCells[0].text = rb;
                    rb += 1;
                }
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 10;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 8;
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.horizontalAlign = "center";
                    pdfCell.backgroundColor = "#D3D3D3";
                    if (gridCell.column.caption === "Konačan iznos") {
                        pdfCell.text = "Iznos";
                    }
                }
            }
        }).then(() => {
            //User data
            const userNameLong =
                state.userData.userType === "business"
                    ? state.userData.companyName
                    : `${state.userData.name} ${state.userData.lastname}`;
            const userName =
                userNameLong.length > 45
                    ? `${userNameLong.substring(0, 44)}...`
                    : userNameLong;
            const userPhone =
                state.userData.tel !== ""
                    ? `Tel: ${state.userData.tel}`
                    : state.userData.mobile !== ""
                    ? `Mob: ${state.userData.mobile}`
                    : `Tel: - `;
            const userData = {
                name: userName,
                street: `${state.userData.address}`,
                city: `${state.userData.postalCode} ${state.userData.city}`,
                oib: `OIB: ${state.userData.vatId}`,
                phone: userPhone,
                email: `Mail: ${state.userData.email}`
            };
            //General data
            const date = new Date(state.invoiceData.date);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const currentDate = `Datum: ${day}.${month}.${year}`;
            const paddingBelowTable = 10;
            const textYPosition = lastPoint.y + paddingBelowTable;
            const pageWidth = doc.internal.pageSize.getWidth();
            //Company data
            // const companyData = {
            //     name: "Info Network d.o.o, OIB: 92134883875",
            //     street: "Valturska 78/1, 52100 Pula",
            //     phone: "T: +385 52 637 000; M: +385 95 731 7698",
            //     email: "Email: prodaja@gtnet.hr",
            //     commercialist: `Komercijalist: ${state.adminUsername}`,
            //     iban: "IBAN: HR3123400091110649671",
            //     swift: "Swift Code: PBZGHR2X",
            //     bank: "Privredna Banka Zagreb",
            // };
            //Invoice data
            //TODO: Mjesto izdavanja u domenu
            //TODO: Način plaćanja u offer
            // const createdDate = new Date(state.invoiceData.date);
            // const expireDate = new Date(state.invoiceData.expireDate);
            // const differenceInMilliseconds = expireDate - createdDate;
            // const differenceInDays = Math.floor(
            //     differenceInMilliseconds / (1000 * 60 * 60 * 24)
            // );
            const remark =
                state.invoiceData.remark !== null
                    ? state.invoiceData.remark
                    : "";
            const invoiceData = {
                number:
                    state.invoiceData.createdById +
                    "-" +
                    state.invoiceData.number +
                    "-" +
                    year,
                place: `Mjesto izdavanja: Pula`,
                payment: `Plaćanje: ${state.invoiceData.paymentMethod}`,
                // daysToExpire: `Ponuda vrijedi: ${differenceInDays} dan/a`,
                accountNumber: `Poziv na broj: ${state.invoiceData.createdById}-${state.invoiceData.number}/${year}`,
                remark: "Napomena: \n\n" + remark,
                fileData: `Račun br: ${state.invoiceData.createdById}-${state.invoiceData.number}/${year}`,
                totalAmountDiscounted:
                    state.invoiceData.totalAmountDiscounted.toFixed(2),
                totalTaxAmount: state.invoiceData.totalTaxAmount.toFixed(2),
                totalAmountTaxed: state.invoiceData.totalAmountTaxed.toFixed(2),
                taxName: state.invoiceData.taxName
            };
            const totalAmountTaxedInCents = invoiceData.totalAmountTaxed * 100;
            const totalAmountTaxedInCents15 = totalAmountTaxedInCents
                .toFixed(0)
                .padStart(15, 0);
            //Barcode data
            const barcodeData = `HRVHUB30
        EUR
        ${totalAmountTaxedInCents15}
        ${userNameLong}
        ${userData.street}
        ${userData.city}
        Info Network d.o.o.
        Valturska 78/1
        52100 Pula
        HR3123400091110649671
        HR00
        ${invoiceData.number}

        Plaćanje po računu ${invoiceData.number}`;
            const barcode = generateBarcode(barcodeData);
            //Footers
            // const footerAdGtNet = "www.gtnet.hr";
            // const footerAdDP = "shop.digitalnaproizvodnja.hr";
            const footerGenerated = `Generirano:`;
            const footerDatetime = getCurrentDateTime();
            //Setting up document
            doc.setFont("Poppins-Regular", "normal");
            doc.setFontSize(10);
            doc.setPage(1);
            doc.text(userData.name, 17, 60);
            doc.setFontSize(10);
            doc.text(currentDate, pageWidth - 65, 60);
            doc.text(invoiceData.place, pageWidth - 65, 65);
            doc.text(invoiceData.payment, pageWidth - 65, 70);
            // doc.text(invoiceData.daysToExpire, pageWidth - 65, 75);
            doc.text(invoiceData.accountNumber, pageWidth - 65, 75);
            doc.text(invoiceData.fileData, pageWidth / 2, 94, {
                align: "center"
            });
            doc.setFontSize(10);
            // doc.setFontSize(12);
            // doc.setFont("Poppins-Bold", "bold");
            // doc.text(companyData.name, 15, 15);
            // doc.text(companyData.street, 15, 20);
            // doc.setFont("Poppins-Regular", "normal");
            // doc.setFontSize(10);
            // doc.text(companyData.phone, 15, 25);
            // doc.text(companyData.email, 15, 30);
            // doc.text(companyData.commercialist, 15, 35);
            // doc.text(companyData.iban, pageWidth - 65, 15);
            // doc.text(companyData.swift, pageWidth - 65, 20);
            // doc.text(companyData.bank, pageWidth - 65, 25);
            // doc.setFontSize(10);
            doc.text(userData.street, 17, 65);
            doc.text(userData.city, 17, 70);
            doc.text(userData.oib, 17, 75);
            doc.text(userData.phone, 17, 80);
            doc.text(userData.email, 17, 85);
            doc.line(15, 87, 110, 87);
            doc.line(15, 56, 110, 56);
            doc.line(15, 56, 15, 87);
            doc.line(110, 56, 110, 87);
            doc.setFontSize(9);
            doc.setPage(doc.internal.getNumberOfPages());
            doc.setFontSize(11);
            doc.text(
                "Osnovica za porez na dodatnu vrijednost (EUR):",
                pageWidth - 45,
                textYPosition,
                { align: "right" }
            );
            doc.text(
                `${invoiceData.totalAmountDiscounted}`,
                pageWidth - 17,
                textYPosition,
                { align: "right" }
            );
            doc.text(
                `${invoiceData.taxName} (EUR):`,
                pageWidth - 45,
                textYPosition + 5,
                {
                    align: "right"
                }
            );
            doc.text(
                `${invoiceData.totalTaxAmount}`,
                pageWidth - 17,
                textYPosition + 5,
                { align: "right" }
            );
            doc.line(
                pageWidth - 100,
                textYPosition + 8,
                pageWidth - 15,
                textYPosition + 8
            );
            doc.line(
                pageWidth - 100,
                textYPosition + 15,
                pageWidth - 15,
                textYPosition + 15
            );
            doc.line(
                pageWidth - 100,
                textYPosition + 8,
                pageWidth - 100,
                textYPosition + 15
            );
            doc.line(
                pageWidth - 15,
                textYPosition + 8,
                pageWidth - 15,
                textYPosition + 15
            );
            doc.setFont("Poppins-Bold", "bold");
            doc.text(
                "Ukupno za platiti (EUR):",
                pageWidth - 45,
                textYPosition + 13,
                {
                    align: "right"
                }
            );
            doc.text(
                `${invoiceData.totalAmountTaxed}`,
                pageWidth - 17,
                textYPosition + 13,
                { align: "right" }
            );
            doc.setFont("Poppins-Regular", "normal");
            doc.addImage(
                barcode,
                "PNG",
                pageWidth - 68,
                textYPosition + 20,
                53,
                13
            );
            doc.text(invoiceData.remark, 15, textYPosition + 50);
            doc.setFontSize(8);
            // doc.text(footerAdGtNet, 15, 281);
            // doc.text(footerAdDP, 15, 285);
            doc.text(footerGenerated, pageWidth - 35, 276);
            doc.text(footerDatetime, pageWidth - 35, 280);
            const pageHeight = doc.internal.pageSize.getHeight();
            const documentName = `${state.invoiceData.createdById}${state.invoiceData.number}-${year}_${userNameLong}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 794,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            if (!sendEmailFlag) {
                                doc.save(documentName);
                            } else {
                                if (doc) {
                                    const pdfForEmail = {
                                        doc: doc,
                                        documentName: documentName
                                    };
                                    eventBus.emit(
                                        "invoicePdfForEmail",
                                        pdfForEmail
                                    );
                                } else {
                                    console.error(
                                        "PDF generation failed: doc is null or undefined"
                                    );
                                }
                            }
                        }
                    });
                }
            });
        });
    }

    return {
        ...toRefs(state),
        getDomainData,
        getInvoiceByKey,
        getUserByKey,
        getBusinessYearByKey,
        handleInvoiceExporting
    };
}
