<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid"
        id="dispatchNoteItemTable"
        :data-source="dispatchNoteItem"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        @initialized="getDispatchNoteItemTableInstance"
    >
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="dispatchNoteItemTableState"
        />
        <DxToolbar>
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxColumnFixing :enabled="true" />
        <DxColumn
            caption="Rb."
            :width="50"
            alignment="center"
            :calculate-cell-value="setRowIndex"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Šifra"
            name="codeColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="code"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Naziv"
            name="nameColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            :allow-exporting="false"
            width="120"
            data-field="price"
            data-type="number"
            caption="Cijena"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Kol."
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="JMJ"
            name="measureUnitColumn"
        >
            <DxLookup
                :data-source="priceListItem"
                value-expr="id"
                display-expr="measureUnit"
            />
        </DxColumn>
        <DxColumn
            :allow-exporting="false"
            width="120"
            data-field="priceTotal"
            data-type="number"
            format="###,###,###,##0.00"
            caption="Ukupno"
        />
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxItem,
    DxPager,
    DxColumnFixing,
    DxToolbar,
    DxScrolling,
    DxLookup,
    DxStateStoring,
    DxSearchPanel
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { useDispatchNoteItem } from "@/composables/useDispatchNoteItem.js";
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted } from "vue";

export default {
    name: "dispatchNoteItemTableComponent",
    components: {
        DxItem,
        DxPager,
        DxDataGrid,
        DxColumn,
        DxSearchPanel,
        DxColumnFixing,
        DxScrolling,
        DxLookup,
        DxStateStoring,
        DxToolbar
        // DxSummary,
        // DxTotalItem,
    },
    setup() {
        const {
            priceListItem,
            dispatchNoteItem,
            priceTotalFormat,
            getDispatchNoteByKey,
            getUserByKey,
            getBusinessYearByKey,
            handleDispatchNoteExporting,
            getDomainData
        } = useDispatchNoteItem();
        onMounted(async () => {
            await getDomainData();
        });
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);

        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });

        const getDispatchNoteItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };

        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };

        const setRowIndex = (rowData) => {
            if (!tableInstance.value) return "";
            const currentPageIndex = tableInstance.value.pageIndex();
            const pageSize = tableInstance.value.pageSize();

            // Find row index in current page (works for UI & export)
            const data = tableInstance.value.getDataSource().items();
            const rowIndexInPage = data.findIndex(
                (item) => item.id === rowData.id
            );

            return currentPageIndex * pageSize + (rowIndexInPage + 1);
        };
        eventBus.on("exportDispatchNote", (dispatchNoteId) => {
            handleDispatchNoteExporting(tableInstance.value, dispatchNoteId);
        });
        eventBus.on("newDispatchNoteItemAdded", () => {
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowDispatchNote", async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getDispatchNoteByKey(data);
                await getUserByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        return {
            refreshButtonOptions,
            setRowIndex,
            priceListItem,
            dispatchNoteItem,
            priceTotalFormat,
            getDispatchNoteItemTableInstance,
            shouldShowTheDataGrid
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#dispatchNoteItemTable {
    width: 100%;
}
</style>
