import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { warehouse } from "@/stores/warehouseStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { businessYear } from "@/stores/businessYearStore";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatusCalculation } from "@/stores/warehouseItemStatusCalculationStore.js";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { divWrapper } from "@/utils/divWrapper";
import { changesLog } from "@/stores/changesLogStore";
export function useWarehouse() {
    const domainUserStore = useDomainUserStore();
    const state = reactive({
        warehouse,
        priceListItem,
        domain: domain,
        domainUser: domainUser,
        businessYear: businessYear,
        warehouseItemStatusCalculation,
        oldDataLog: null,
        domainName: null,
        warehouseData: null,
        warehouseName: null,
        documentHeader: null,
        documentFooter: null,
        columnChooserMode: "select",
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        admin: domainUserStore.getDomainUser,
        businessYearId: localStorage.getItem("businessYearId")
    });
    async function fetchWarehouseDataByKey(id) {
        try {
            state.warehouseData = await state.warehouse.byKey(id);
            state.warehouseName = state.warehouseData.name;
        } catch (error) {
            console.error("Error fetching warehouse data by key:", error);
            throw error;
        }
    }
    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        if (domainData !== null) state.domainName = domainData.name;
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    function handleRowInit(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: state.admin.domainId,
            businessYearId: Number(state.businessYearId)
        };
    }

    function handleRowUpdating(e) {
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: state.admin.id,
            modifiedDate: now.toISOString()
        };
        state.oldDataLog = [{ priceList: e.oldData }];
    }
    async function handleRowUpdatedWarehouse(e) {
        const newData = await warehouse.byKey(e.key);
        const newDataLog = [{ warehouse: newData }];
        const changesLogData = {
            actionType: "UPDATE",
            documentType: "warehouse",
            oldValue: JSON.stringify(state.oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };

        await changesLog.insert(changesLogData);
    }
    async function handleRowInsertedWarehouse(e) {
        const logData = [{ warehouse: e.data }];
        const changesLogData = {
            actionType: "INSERT",
            documentType: "warehouse",
            oldValue: null,
            newValue: JSON.stringify(logData),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
    }
    async function handleExportCalculatedWarehouseItemStatus(
        table,
        date,
        warehouse
    ) {
        await fetchWarehouseDataByKey(warehouse);
        const doc = new jsPDF();
        doc.setFontSize(8);
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            topLeft: { x: 1, y: 40 },
            repeatHeaders: true,
            onRowExporting: (e) => {
                e.rowHeight = 10;
            },
            columnWidths: [12, 76, 20, 25, 15, 12, 20],
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }

                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            }
        }).then(() => {
            const now = new Date();
            const selectedDate = new Date(date);
            const vrijeme = "Vrijeme izrade: " + now.toLocaleString("hr-HR");
            const subHeader =
                "Odabrani datum: " + selectedDate.toLocaleString("hr-HR");
            const header = `Skladište: ${state.warehouseName}`;
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(8);
            doc.setPage(1);
            doc.text(vrijeme, pageWidth - 15, 50, { align: "right" });
            doc.setFontSize(12);
            doc.text(header, 15, 45);
            doc.setFontSize(8);
            doc.text(subHeader, 15, 50);
            doc.line(15, 52, pageWidth - 15, 52);
            const documentName = `Stanje-skladišta-${selectedDate.toLocaleString(
                "hr-HR"
            )}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        }
                    });
                }
            });
        });
    }

    return {
        ...toRefs(state),
        handleRowInit,
        handleRowUpdating,
        handleExportCalculatedWarehouseItemStatus,
        getDomainData,
        handleRowUpdatedWarehouse,
        handleRowInsertedWarehouse
    };
}
