<template>
    <!-- <div class="table-container"> -->
    <span class="table-name">Kupci</span>
    <DxDataGrid
        id="customerTable"
        :data-source="dataSource"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        @initialized="getCustomerTableInstance"
        @row-updating="onRowUpdatingCustomer"
        @row-updated="onRowUpdatedCustomer"
        @row-inserting="onRowInsertingCustomer"
        @row-inserted="onRowInsertedCustomer"
        @init-new-row="onInitNewRowCustomer"
    >
        <DxToolbar>
            <DxItem
                :options="addButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="exportButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="customerTableState"
        />

        <DxSearchPanel :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPaging :page-size="20" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />

        <DxEditing
            :allow-deleting="true"
            :allow-updating="true"
            :use-icons="true"
            mode="popup"
        >
            <DxPopup
                :show-title="true"
                title="Dodaj novog kupca"
                :toolbar-items="popupToolbarItems"
                :drag-outside-boundary="true"
            />
            <DxForm @initialized="getCustomerFormInstance">
                <DxItem :col-count="1" :col-span="2" item-type="group">
                    <DxButtonItem
                        :button-options="fetchCompanyDataButtonOptions"
                        horizontal-alignment="left"
                    />
                </DxItem>
                <DxItem
                    :col-count="3"
                    :col-span="2"
                    item-type="group"
                    caption="Informacije o kupcu"
                >
                    <DxItem
                        data-field="id"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem
                        data-field="vatId"
                        data-type="string"
                        :editor-options="oibOptions"
                    >
                        <DxRequiredRule />
                        <DxStringLengthRule
                            :max="11"
                            :min="11"
                            message="OIB mora sadržavati 11 brojeva."
                        />
                    </DxItem>

                    <DxItem data-field="name" data-type="string">
                        <DxRequiredRule
                    /></DxItem>
                    <DxItem data-field="lastname" data-type="string">
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem data-field="sign" data-type="string">
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem
                        data-field="companyName"
                        data-type="string"
                        :editor-options="companyNameOptions"
                        ><DxRequiredRule v-if="userType === 'business'"
                    /></DxItem>
                    <DxItem
                        data-field="userType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :editor-options="userTypeSelectBoxOptions"
                    >
                        <DxRequiredRule
                    /></DxItem>
                </DxItem>
                <DxItem
                    :col-count="3"
                    :col-span="2"
                    item-type="group"
                    caption="Adresa/Kontakt"
                >
                    <DxItem
                        data-field="postalCode"
                        data-type="string"
                        :editor-options="zipCodeOptions"
                    >
                    </DxItem>
                    <DxItem
                        data-field="city"
                        data-type="string"
                        :editor-options="cityOptions"
                    ></DxItem>
                    <DxItem
                        data-field="address"
                        data-type="string"
                        :editor-options="streetOptions"
                    ></DxItem>
                    <DxItem
                        data-field="country"
                        data-type="string"
                        :editor-options="countryOptions"
                    ></DxItem>
                    <DxItem
                        data-field="email"
                        data-type="string"
                        :editor-options="emailOptions"
                    >
                        <DxEmailRule />
                    </DxItem>
                    <DxItem data-field="mobile" data-type="string"></DxItem>
                    <DxItem data-field="tel" data-type="string"></DxItem>
                    <DxItem data-field="fax" data-type="string"></DxItem>
                </DxItem>
                <DxItem
                    :col-count="3"
                    :col-span="2"
                    item-type="group"
                    caption="Ostale informacije"
                >
                    <DxItem
                        data-field="billDeliveryMethod"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :editor-options="billDeliveryMethodSelectBoxOptions"
                    ></DxItem>
                    <DxItem data-field="note" data-type="string"></DxItem>
                    <DxItem
                        data-field="status"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :editor-options="statusSelectBoxOptions"
                    ></DxItem>
                    <DxItem
                        data-field="newsletter"
                        editor-type="dxSelectBox"
                        :editor-options="newsletterSelectBoxOptions"
                        data-type="number"
                    ></DxItem>
                </DxItem>
            </DxForm>
        </DxEditing>
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            :show-in-column-chooser="false"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="status"
            data-type="string"
            caption="Status"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="sign"
            data-type="string"
            caption="Skr. naziv"
            :allow-hiding="false"
        />
        <DxColumn
            data-field="vatId"
            data-type="string"
            caption="OIB"
            :allow-hiding="false"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="name"
            data-type="string"
            caption="Ime"
            :allow-hiding="false"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="lastname"
            data-type="string"
            caption="Prezime"
            :allow-hiding="false"
            :allow-exporting="false"
        />

        <DxColumn
            data-field="companyName"
            data-type="string"
            caption="Naziv firme"
            :allow-hiding="false"
        />
        <DxColumn
            data-field="userType"
            data-type="string"
            caption="Tip kupca"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="address"
            data-type="string"
            caption="Adresa"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="city"
            data-type="string"
            caption="Grad"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="postalCode"
            data-type="string"
            caption="Poštanski broj"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="country"
            data-type="string"
            caption="Država"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="tel"
            data-type="string"
            caption="Telefon"
            :allow-exporting="false"
        />
        <DxColumn data-field="mobile" data-type="string" caption="Mobitel" />
        <DxColumn data-field="email" data-type="string" caption="E-mail" />
        <DxColumn
            data-field="fax"
            data-type="string"
            caption="Fax"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="billDeliveryMethod"
            data-type="string"
            caption="Način dostave računa"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="note"
            data-type="string"
            caption="Napomena"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="newsletter"
            data-type="string"
            caption="Novine"
            :allow-exporting="false"
        />

        <DxColumn
            data-field="dateCreated"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Kreirano"
            :allow-exporting="false"
        />
        <!-- <DxColumn
                data-field="createdDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
                :allow-exporting="false"
            /> -->
        <DxColumn
            data-field="createdById"
            data-type="number"
            caption="Kreirao"
            :allow-exporting="false"
        >
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Uređeno"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="modifiedById"
            data-type="number"
            caption="Uredio"
            :allow-exporting="false"
        >
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="domainId"
            data-type="number"
            caption="Domena"
            :visible="false"
            :allow-hiding="false"
            :show-in-column-chooser="false"
            :allow-exporting="false"
        />
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton
                hint="Uredi"
                icon="rename"
                :visible="true"
                @click="editCustomer"
            />
            <DxButton
                hint="Obriši"
                icon="clear"
                :visible="isDeleted"
                @click="deleteCustomer"
            />
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
    </DxDataGrid>
    <SudregPopup
        :resize-enabled="true"
        :max-width="1000"
        :drag-outside-boundary="true"
        :toolbarItems="sudregPopupToolbarItems"
        title="Učitaj podatke iz sudskog registra"
        @initialized="getSudregPopupInstance"
    >
        <DxDataGrid
            id="sudregDataTable"
            :data-source="sudregApi"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            no-data-text="Započnite pretraživanje"
            @initialized="getSudregDataTableInstance"
        >
            <DxToolbar>
                <DxItem
                    location="before"
                    locate-in-menu="auto"
                    widget="dxTextBox"
                    :options="searchBarOptions"
                />
            </DxToolbar>
            <DxPaging :page-size="20" />
            <DxColumnFixing :enabled="true" />
            <DxPager
                :allowed-page-sizes="[]"
                :visible="true"
                display-mode="adaptive"
                :show-info="true"
                info-text="Stranica {0} od {1} ({2} stavki)"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxColumn
                type="buttons"
                :width="70"
                :show-in-column-chooser="false"
                :fixed="true"
                caption="Učitaj"
                fixed-position="left"
            >
                <DxButton
                    hint="Učitaj"
                    icon="chevrondoubleright"
                    :visible="true"
                    @click="fetchCompanyData"
                />
            </DxColumn>
            <DxColumn data-field="mbs" data-type="string" caption="MBS" />
            <DxColumn data-field="oib" data-type="string" caption="OIB" />
            <DxColumn data-field="ime" data-type="string" caption="Naziv" />
        </DxDataGrid>
    </SudregPopup>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxItem,
    DxForm,
    DxPager,
    DxPopup,
    DxButton,
    DxPaging,
    DxColumn,
    DxLookup,
    DxToolbar,
    DxEditing,
    DxDataGrid,
    DxEmailRule,
    DxScrolling,
    DxSearchPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxRequiredRule,
    DxColumnChooser,
    DxStringLengthRule,
    DxColumnChooserSelection,
    DxColumnFixing
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { DxPopup as SudregPopup } from "devextreme-vue/popup";
import { DxButtonItem } from "devextreme-vue/cjs/form";
import { confirm } from "devextreme/ui/dialog";
import { ref, onMounted } from "vue";
import eventBus from "../../eventBus.js";
import { useCustomer } from "@/composables/useCustomer";
import { useSudregApiStore } from "@/stores/customSudregApiStore";

export default {
    name: "customerTableComponent",
    components: {
        DxItem,
        DxForm,
        DxPager,
        DxPopup,
        DxButton,
        DxPaging,
        DxColumn,
        DxLookup,
        DxToolbar,
        DxEditing,
        DxDataGrid,
        DxEmailRule,
        DxButtonItem,
        DxScrolling,
        DxSearchPanel,
        DxHeaderFilter,
        DxStateStoring,
        DxRequiredRule,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxStringLengthRule,
        SudregPopup,
        DxColumnFixing
    },
    setup() {
        const sudregApiStore = useSudregApiStore();
        const {
            domainUser,
            customer,
            user,
            sudregApi,
            columnChooserMode,
            customerTypes,
            status,
            billDeliveryMethods,
            newsletterOptions,
            saveLastData,
            lastSavedData,
            admin,
            handleNewRowInitCustomer,
            handleRowUpdatedCustomer,
            handleRowInsertingCustomer,
            handleRowInsertedCustomer,
            handleRowUpdatingCustomer,
            handleRowRemovingCustomer,
            handleExportCustomer,
            getDomainData
        } = useCustomer();
        const dataSource = admin.value.domainId === 1 ? user : customer;
        onMounted(async () => {
            await getDomainData();
        });

        const userType = ref("");

        //instances
        const tableInstance = ref(null);
        const userTypeInstance = ref(null);
        const sudregPopupInstance = ref(null);
        const sudregApiDataTableInstance = ref(null);
        const searchBarInstance = ref(null);
        const customerFormInstance = ref(null);
        const oibInstance = ref(null);
        const companyNameInstance = ref(null);
        const zipCodeInstance = ref(null);
        const cityInstance = ref(null);
        const streetInstance = ref(null);
        const countryInstance = ref(null);
        const emailInstance = ref(null);

        //button options
        const addButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addCustomer();
            }
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            }
        });
        const exportButtonOptions = ref({
            icon: "export",
            onClick: () => {
                exportCustomer();
            }
        });
        const fetchCompanyDataButtonOptions = ref({
            text: "Učitaj podatke iz sudskog registra",
            stylingMode: "contained",
            width: 300,
            type: "default",
            onClick: () => {
                openSudregPopup();
            }
        });
        const searchBarOptions = ref({
            width: 240,
            label: "Pretraživanje",
            labelMode: "floating",
            onInitialized: (e) => {
                searchBarInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                eventBus.emit("searchValue", e.value);
                sudregApiDataTableInstance.value.refresh();
            }
        });

        //toolbar items
        const popupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi i novi",
                    onClick: () => saveAndContinueCustomer()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveCustomer()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelCustomer()
                }
            }
        ]);
        const sudregPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => sudregPopupInstance.value.hide()
                }
            }
        ]);
        const oibOptions = ref({
            onInitialized: (e) => {
                oibInstance.value = e.component;
            }
        });
        const companyNameOptions = ref({
            onInitialized: (e) => {
                companyNameInstance.value = e.component;
            }
        });
        const zipCodeOptions = ref({
            onInitialized: (e) => {
                zipCodeInstance.value = e.component;
            }
        });
        const cityOptions = ref({
            onInitialized: (e) => {
                cityInstance.value = e.component;
            }
        });
        const streetOptions = ref({
            onInitialized: (e) => {
                streetInstance.value = e.component;
            }
        });
        const countryOptions = ref({
            onInitialized: (e) => {
                countryInstance.value = e.component;
            }
        });
        const emailOptions = ref({
            onInitialized: (e) => {
                emailInstance.value = e.component;
            }
        });

        //form item options
        const userTypeSelectBoxOptions = ref({
            items: customerTypes.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi tip kupca",
            onInitialized: (e) => {
                userTypeInstance.value = e.component;
            },
            onSelectionChanged: (e) => {
                userType.value = e.selectedItem.value;
            }
        });
        const billDeliveryMethodSelectBoxOptions = ref({
            items: billDeliveryMethods.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi način dostave"
        });
        const statusSelectBoxOptions = ref({
            items: status.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi status kupca"
        });
        const newsletterSelectBoxOptions = ref({
            items: newsletterOptions.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi pretplatu"
        });

        //instance getters
        const getCustomerTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getSudregPopupInstance = (e) => {
            sudregPopupInstance.value = e.component;
        };
        const getCustomerFormInstance = (e) => {
            customerFormInstance.value = e.component;
        };
        const getSudregDataTableInstance = (e) => {
            sudregApiDataTableInstance.value = e.component;
        };

        //supplier table methods
        const addCustomer = () => {
            tableInstance.value.addRow();
        };
        const editCustomer = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelCustomer = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteCustomer = (e) => {
            let res = confirm("Jeste li sigurni da želite obrisati kupca?");
            res.then(async (c) => {
                if (c) {
                    await handleRowRemovingCustomer(e);
                    tableInstance.value.refresh();
                }
            });
        };
        const onInitNewRowCustomer = (e) => {
            handleNewRowInitCustomer(e);
        };
        const saveCustomer = () => {
            saveLastData.value = false;
            lastSavedData.value = {};
            tableInstance.value.saveEditData();
        };
        const saveAndContinueCustomer = () => {
            saveLastData.value = true;
            tableInstance.value.saveEditData();
        };
        const onRowInsertingCustomer = (e) => {
            e.data = handleRowInsertingCustomer(e);
        };
        const onRowInsertedCustomer = async (e) => {
            if (saveLastData.value) {
                addCustomer();
            }
            await handleRowInsertedCustomer(e);
            tableInstance.value.refresh();
        };
        const onRowUpdatingCustomer = async (e) => {
            e.newData = await handleRowUpdatingCustomer(e);
        };
        const onRowUpdatedCustomer = async (e) => {
            await handleRowUpdatedCustomer(e);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const exportCustomer = () => {
            handleExportCustomer(tableInstance.value);
        };

        const openSudregPopup = () => {
            sudregPopupInstance.value.show();
        };
        const isDeleted = (e) => {
            return e.row.data.status === "inactive" ? false : true;
        };
        //Funkcija za dohvat podataka iz sudskog registra
        const fetchCompanyData = async (e) => {
            const res = await sudregApiStore.fetchCompanyData({
                tip_identifikatora: "oib",
                identifikator: e.row.data.oib.toString()
            });
            oibInstance.value.option(
                "value",
                res.potpuni_oib ? res.potpuni_oib : res.oib
            );
            companyNameInstance.value.option("value", res.tvrtka.ime);
            cityInstance.value.option(
                "value",
                res.sjediste.naziv_naselja ? res.sjediste.naziv_naselja : ""
            );
            streetInstance.value.option(
                "value",
                res.sjediste.ulica && res.sjediste.kucni_broj
                    ? res.sjediste.ulica + " " + res.sjediste.kucni_broj
                    : ""
            );
            countryInstance.value.option("value", "Hrvatska");
            emailInstance.value.option(
                "value",
                res.email_adrese ? res.email_adrese[0].adresa : ""
            );
            userTypeInstance.value.option("value", "business");
            sudregPopupInstance.value.hide();
            eventBus.emit("searchValue", "");
            searchBarInstance.value.option("value", "");
        };
        return {
            domainUser,
            customer,
            dataSource,
            columnChooserMode,
            customerTypes,
            handleNewRowInitCustomer,
            handleRowInsertingCustomer,
            handleRowUpdatingCustomer,
            handleExportCustomer,
            getCustomerTableInstance,
            editCustomer,
            deleteCustomer,
            onInitNewRowCustomer,
            onRowInsertingCustomer,
            onRowInsertedCustomer,
            onRowUpdatingCustomer,
            addButtonOptions,
            refreshButtonOptions,
            columnChooserButtonOptions,
            exportButtonOptions,
            popupToolbarItems,
            userTypeSelectBoxOptions,
            userType,
            billDeliveryMethodSelectBoxOptions,
            statusSelectBoxOptions,
            newsletterSelectBoxOptions,
            fetchCompanyDataButtonOptions,
            oibOptions,
            companyNameOptions,
            zipCodeOptions,
            cityOptions,
            streetOptions,
            countryOptions,
            emailOptions,
            sudregApi,
            searchBarOptions,
            getSudregPopupInstance,
            getCustomerFormInstance,
            getSudregDataTableInstance,
            openSudregPopup,
            fetchCompanyData,
            sudregPopupToolbarItems,
            onRowUpdatedCustomer,
            isDeleted
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#supplierTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
}
</style>
