import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
// import { user } from "@/stores/userStore";
import { customer } from "@/stores/customerStore";
import { domainUser } from "@/stores/domainUserStore";
import { receipt } from "@/stores/receiptStore";
import { supplier } from "@/stores/supplierStore";
import { priceList } from "@/stores/priceListStore";
import { warehouse } from "@/stores/warehouseStore";
import { receiptItem } from "@/stores/receiptItemStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore";
// import {
//     // updateWarehouseItemStatusOnNewReceipt,
//     // updateWarehouseItemStatusOnUpdatedReceipt,
//     // updateWarehouseItemStatusOnRemovedReceipt,
// } from "@/utils/updateWarehouseItemStatus";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { poppinsBold } from "@/poppinsboldbase64.js";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { getCurrentDateTime } from "@/utils/getCurrentDateTime";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { changesLog } from "@/stores/changesLogStore";
import { useWarehouseItemStore } from "@/stores/customWarehouseItemStatusStore";

export function useReceipt() {
    const domainUserStore = useDomainUserStore();
    const warehouseItemStatusStore = useWarehouseItemStore();
    const state = reactive({
        domain,
        domainUser,
        // user,
        priceList,
        customer,
        receipt,
        supplier,
        warehouse,
        receiptItem,
        businessYear,
        priceListItem,
        warehouseItemStatus,
        editingReceipt: null,
        editingReceiptItem: null,
        rowsToBeDeleted: [],
        receiptItemData: [],
        receiptItemsBeforeEdit: [],
        editingReceiptItemOldData: [],
        editingReceiptItemWarehouseItemStatus: [],
        newEditingReceiptItemWarehouseItemStatus: [],
        columnChooserMode: "select",
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        admin: domainUserStore.getDomainUser,
        businessYearId: localStorage.getItem("businessYearId")
    });
    function handleRowInsertingReceipt(e, number, date) {
        const now = new Date().toISOString();
        delete e.data.id;
        e.data = {
            ...e.data,
            number: number,
            date: date,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now,
            modifiedDate: now,
            domainId: state.admin.domainId,
            status: "active"
        };
    }
    async function handleRowInsertedReceipt(e) {
        let receiptPriceTotal = 0;
        const now = new Date().toISOString();
        const allItemPromises = state.receiptItemData.map((item) => {
            if (item.id) {
                delete item.id;
            }
            delete item.__KEY__;
            item.receiptId = e.key;
            item.priceTotal = Number(item.priceTotal);
            item.createdById = state.admin.id;
            item.modifiedById = state.admin.id;
            item.createdDate = now;
            item.modifiedDate = now;
            item.status = "active";
            receiptPriceTotal += item.priceTotal;
            return item;
            // return Promise.all([
            //     // receiptItem.insert(item),
            //     // priceListItem.update(item.priceListItemId, {
            //     //     latestProcurementPrice: item.price,
            //     // }),
            //     updateWarehouseItemStatusOnNewReceipt(
            //         item,
            //         e.data.warehouseId,
            //         state.admin.domainId
            //     ),
            // ]);
        });
        // await Promise.all(allItemPromises);
        await receipt.update(e.key, {
            priceTotal: Number(receiptPriceTotal)
        });
        state.receiptItemData = [];

        const updatedReceipt = await receipt.byKey(e.key);
        const logData = [{ receipt: updatedReceipt }];
        const receiptItems = await receiptItem.load({ receiptId: e.key });
        receiptItems.forEach((item) => {
            logData.push({ item });
        });
        const changesLogData = {
            actionType: "INSERT",
            documentType: "receipt",
            oldValue: null,
            newValue: JSON.stringify(logData),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
        await warehouseItemStatusStore.updateWarehouseItemStatusOnNewReceipt({
            warehouseId: e.data.warehouseId,
            items: allItemPromises,
            domainId: e.data.domainId
        });
    }
    async function handleEditingStartReceipt(e) {
        state.editingReceipt = e.data;
        await receiptItem.load();
    }
    function handleEditCanceledReceipt() {
        state.receiptItemData = [];
        state.rowsToBeDeleted = [];
    }
    async function handleRowUpdatingReceipt(e) {
        let receiptPriceTotal = 0;
        const now = new Date().toISOString();
        // const allPromises = [];
        const snapshotReceiptItems = [...state.receiptItemData];
        const oldReceipt = await receipt.byKey(e.key);
        const oldReceiptItems = await receiptItem.load({ receiptId: e.key });
        const oldDataLog = [{ receipt: oldReceipt }];
        oldReceiptItems.forEach((item) => {
            oldDataLog.push({ item });
        });

        // const deletePromises = state.rowsToBeDeleted.map((item) => {
        //     return receiptItem
        //         .remove(item.id)
        //         .then(() => {
        //             const loadOptions = {
        //                 priceListItemId: item.priceListItemId,
        //                 warehouseId: e.oldData.warehouseId,
        //             };
        //             return warehouseItemStatus.load(loadOptions);
        //         })
        //         .then((res) => {
        //             if (res.length > 0) {
        //                 const newQty = res[0].qty - item.qty;
        //                 const updateData = { qty: newQty };
        //                 return warehouseItemStatus.update(
        //                     res[0].id,
        //                     updateData
        //                 );
        //             }
        //         });
        // });
        state.rowsToBeDeleted = [];
        // allPromises.push(...deletePromises);
        // allPromises.push(
        //     updateWarehouseItemStatusOnUpdatedReceipt(
        //         state.newEditingReceiptItemWarehouseItemStatus,
        //         state.editingReceiptItemOldData,
        //         snapshotReceiptItems,
        //         e.oldData.warehouseId,
        //         state.admin.domainId
        //     )
        // );
        const receiptItemPromises = snapshotReceiptItems.map((item) => {
            if (item.id) {
                delete item.id;
            }
            if (item.__KEY__) {
                delete item.__KEY__;
            }
            item.receiptId = e.key;
            item.priceTotal = Number(item.priceTotal);
            item.createdById = state.admin.id;
            item.modifiedById = state.admin.id;
            item.createdDate = now;
            item.modifiedDate = now;
            item.status = "active";
            receiptPriceTotal += item.priceTotal;
            return item;
            // return Promise.all([
            //     receiptItem.insert(item),
            //     priceListItem.update(item.priceListItemId, {
            //         latestProcurementPrice: item.price,
            //     }),
            // ]).then(() => {
            //     receiptPriceTotal += item.priceTotal;
            // });
        });
        await warehouseItemStatusStore.updateWarehouseItemStatusOnUpdatedReceipt(
            {
                warehouseId: e.oldData.warehouseId,
                receiptId: e.key,
                newItems: receiptItemPromises,
                domainId: e.oldData.domainId
            }
        );
        // allPromises.push(...receiptItemPromises);
        // await Promise.all(allPromises);
        await receipt.update(e.key, {
            priceTotal: Number(receiptPriceTotal)
        });
        const updatedReceipt = await receipt.byKey(e.key);
        const updatedReceiptItems = await receiptItem.load({
            receiptId: e.key
        });

        const newDataLog = [{ receipt: updatedReceipt }];
        updatedReceiptItems.forEach((item) => {
            newDataLog.push({ item });
        });

        const changesLogData = {
            actionType: "UPDATE",
            documentType: "receipt",
            oldValue: JSON.stringify(oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: now
        };

        await changesLog.insert(changesLogData);

        // state.rowsToBeDeleted = [];
        state.receiptItemData = [];
        state.newEditingReceiptItemWarehouseItemStatus = [];
        state.editingReceiptItemOldData = [];
    }
    async function handleRowRemovingReceipt(e) {
        const oldReceipt = await receipt.byKey(e.row.data.id);
        const oldReceiptItems = await receiptItem.load({
            receiptId: e.row.data.id
        });
        const oldDataLog = [{ receipt: oldReceipt }];
        oldReceiptItems.forEach((item) => {
            oldDataLog.push({ item });
        });
        await receipt.update(e.row.data.id, { status: "deleted" });
        await warehouseItemStatusStore.updateWarehouseItemStatusOnRemovedReceipt(
            {
                warehouseId: e.row.data.warehouseId,
                domainId: e.row.data.domainId,
                receiptId: e.row.data.id
            }
        );
        // const receiptItemPromises = state.receiptItemData.map((item) => {
        //     return receiptItem
        //         .update(item.id, { status: "deleted" })
        //         .then(() => {
        //             return updateWarehouseItemStatusOnRemovedReceipt(
        //                 item,
        //                 e.row.data.warehouseId,
        //                 state.admin.domainId
        //             );
        //         });
        // });
        // await Promise.all(receiptItemPromises);
        const changesLogData = {
            actionType: "DELETE",
            documentType: "receipt",
            oldValue: JSON.stringify(oldDataLog),
            newValue: null,
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };

        await changesLog.insert(changesLogData);
    }
    async function handleExportingReceiptRecap(table) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFileToVFS("@/assets/Poppins-Bold.ttf", poppinsBold);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.addFont("@/assets/Poppins-Bold.ttf", "Poppins-Bold", "bold");
        doc.setFont("Poppins-Regular", "normal");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        const combinedFilter = table.getCombinedFilter(true);
        let filterValueDate = null;
        let filterValueYear = null;
        let filterValueWarehouse = null;
        if (combinedFilter && combinedFilter[0] === "warehouseId") {
            filterValueWarehouse = combinedFilter.filterValue;
        }
        if (combinedFilter && combinedFilter[0][0] === "date") {
            if (typeof combinedFilter.filterValue === "string") {
                filterValueDate = combinedFilter.filterValue;
            } else {
                filterValueYear = combinedFilter.filterValue;
            }
        }
        if (combinedFilter) {
            if (
                combinedFilter[0][0] === "warehouseId" &&
                combinedFilter[0].filterValue
            ) {
                filterValueWarehouse = combinedFilter[0].filterValue;
            }
            if (
                combinedFilter[2].filterValue &&
                combinedFilter[2][0][0] === "date"
            ) {
                if (
                    combinedFilter[2].filterValue &&
                    typeof combinedFilter[2].filterValue === "string"
                ) {
                    filterValueDate = combinedFilter[2].filterValue;
                } else if (
                    combinedFilter[2].filterValue &&
                    typeof combinedFilter[2].filterValue === "number"
                ) {
                    filterValueYear = combinedFilter[2].filterValue;
                }
            }
        }
        let warehouseName = null;
        if (filterValueWarehouse) {
            const warehouseData = await warehouse.byKey(filterValueWarehouse);
            warehouseName = warehouseData.name;
        }
        let formatedWarehouseName = null;
        if (warehouseName) {
            formatedWarehouseName = `Skladište: ${warehouseName}`;
        } else {
            formatedWarehouseName = "Skladište: Nije odabrano";
        }
        let formattedDateRange = null;
        if (filterValueDate) {
            const [year, month] = filterValueDate.split("/").map(Number);
            const startDate = new Date(year, month - 1, 1);
            const endDate = new Date(year, month, 0);
            const formatDate = (date) => {
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;
            };
            const startString = formatDate(startDate);
            const endString = formatDate(endDate);
            formattedDateRange = `Datum: od ${startString}. do ${endString}.`;
        } else if (filterValueYear) {
            formattedDateRange = `Datum: Godina ${filterValueYear}.`;
        } else {
            formattedDateRange = `Datum: Nije odabran`;
        }
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            repeatHeaders: true,
            topLeft: { x: 1, y: 20 },
            onRowExporting: (e) => {
                e.rowHeight = 10;
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 10;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.borderColor = "#D3D3D3";
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.horizontalAlign = "center";
                    pdfCell.backgroundColor = "#D3D3D3";
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 9;
                }
            }
        }).then(() => {
            const pageWidth = doc.internal.pageSize.getWidth();
            const generatedTime = getCurrentDateTime();
            const generated = `Vrijeme izrade: ${generatedTime}`;
            doc.setFontSize(10);
            doc.line(15, 15, pageWidth - 15, 15);
            doc.setFont("Poppins-Bold", "bold");
            doc.text("Rekapitulacija primki", 15, 20);
            doc.setFont("Poppins-Regular", "normal");
            doc.text(generated, pageWidth - 15, 20, { align: "right" });
            doc.text(formatedWarehouseName, 15, 27);
            doc.text(formattedDateRange, 15, 32);
            doc.save("Rekapitulacija-primki.pdf");
        });
    }
    function handleCancelReceiptItem() {
        state.editingReceiptItemOldData.pop();
        state.editingReceiptItem = null;
    }
    function handleSaveNewReceiptItem(qty, price, priceTotal) {
        const now = new Date().toISOString();
        if (state.editingReceiptItem !== null) {
            state.editingReceiptItem.qty = qty;
            state.editingReceiptItem.price = price;
            state.editingReceiptItem.priceTotal = Number(priceTotal);
            state.editingReceiptItem.modifiedDate = now;
            state.editingReceiptItem.modifiedById = state.admin.id;
            state.newEditingReceiptItemWarehouseItemStatus.push(
                state.editingReceiptItem
            );
            state.receiptItemData.find((el) => {
                if (
                    state.editingReceiptItem.__KEY__ &&
                    el.__KEY__ === state.editingReceiptItem.__KEY__
                ) {
                    el = state.editingReceiptItem;
                } else if (el.id === state.editingReceiptItem.id) {
                    el = state.editingReceiptItem;
                }
            });
            state.editingReceiptItem = null;
        } else {
            state.editingReceiptItem = null;
        }
    }
    function handleRowInsertingReceiptItem(
        e,
        qty,
        price,
        priceTotal,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: priceTotal,
            priceListItemId: priceListItemId
        };
    }
    function handleEditingStartReceiptItem(e) {
        state.editingReceiptItem = e.data;
        const oldData = {
            ...state.editingReceiptItem
        };
        state.editingReceiptItemOldData.push(oldData);
    }

    return {
        ...toRefs(state),
        handleRowInsertingReceipt,
        handleRowInsertedReceipt,
        handleEditingStartReceipt,
        handleEditCanceledReceipt,
        handleRowUpdatingReceipt,
        handleRowRemovingReceipt,
        handleExportingReceiptRecap,
        handleCancelReceiptItem,
        handleSaveNewReceiptItem,
        handleRowInsertingReceiptItem,
        handleEditingStartReceiptItem
    };
}
