import { reactive, toRefs } from "vue";
import { dispatchNote } from "@/stores/dispatchNoteStore.js";
import { dispatchNoteItem } from "@/stores/dispatchNoteItemStore.js";
import { user } from "@/stores/userStore.js";
import { customer } from "@/stores/customerStore.js";
import { domain } from "@/stores/domainStore";
import { invoice } from "@/stores/invoiceStore.js";
import { warehouse } from "@/stores/warehouseStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { invoiceItem } from "@/stores/invoiceItemStore.js";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import { emailSignatureDefault } from "@/stores/emailSignatureDefaultStore.js";
import { warehouseItemStatusPriceAverage } from "@/stores/warehouseItemStatusPriceAverageStore.js";
import // updateWarehouseItemStatusOnNewDispatchNote
// updateWarehouseItemStatusOnRemovedDispatchNote
"@/utils/updateWarehouseItemStatus.js";
import eventBus from "../../eventBus.js";
import moment from "moment";
import { generateInvoiceData } from "@/utils/generateInvoiceData.js";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { poppinsBold } from "@/poppinsboldbase64.js";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { getCurrentDateTime } from "@/utils/getCurrentDateTime";
import { divWrapper } from "@/utils/divWrapper";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { tax } from "@/stores/taxStore.js";
import { changesLog } from "@/stores/changesLogStore";
import { useWarehouseItemStore } from "@/stores/customWarehouseItemStatusStore.js";

export function useInvoice() {
    const domainUserStore = useDomainUserStore();
    const warehouseItemStore = useWarehouseItemStore();
    const state = reactive({
        dispatchNote,
        dispatchNoteItem,
        user,
        customer,
        domain,
        invoice,
        warehouse,
        domainUser,
        invoiceItem,
        businessYear,
        priceListItem,
        warehouseItemStatus,
        emailSignatureDefault,
        warehouseItemStatusPriceAverage,
        taxId: null,
        taxValue: null,
        taxName: null,
        documentHeader: null,
        documentFooter: null,
        domainName: null,
        editingInvoice: null,
        editingInvoiceItem: null,
        signature: null,
        invoiceItemData: [],
        rowsToBeDeleted: [],
        invoiceItemsBeforeEdit: [],
        editingInvoiceItemOldData: [],
        columnChooserMode: "select",
        userSignature: null,
        pdfForEmail: null,
        emailFormData: {
            sourceType: "proforma",
            sourceId: null,
            senderId: null,
            dateSent: null,
            clientId: null,
            emailFrom: null,
            emailTo: null,
            subject: null,
            message: null
        },
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        admin: domainUserStore.getDomainUser,
        businessYearId: localStorage.getItem("businessYearId")
    });
    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        state.domainName = domainData.name;
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    async function updateTaxValue(id) {
        const res = await tax.byKey(id);
        state.taxValue = res.taxValue;
        state.taxName = res.taxName;
    }
    function handleRowInsertingInvoice(e, date, number, delay, warehouseId) {
        const now = new Date();
        delete e.data.id;
        e.data = {
            ...e.data,
            date: date,
            number: number,
            delay: delay,
            delivery: date,
            paymentDate: null,
            exchangeRate: null,
            returnAmount: null,
            warehouseId: warehouseId,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: state.admin.domainId,
            status: "new",
            taxId: state.taxId,
            taxValue: state.taxValue,
            taxName: state.taxName
        };
    }
    async function handleRowInsertedInvoice(e, invoiceItemData = null) {
        if (invoiceItemData !== null) state.invoiceItemData = invoiceItemData;
        let totalPriceByQty = 0;
        let totalDiscountAmount = 0;
        let totalAmountDiscounted = 0;
        const userData =
            state.admin.domainId === 1
                ? await user.byKey(e.data.userId)
                : await customer.byKey(e.data.userId);
        const now = new Date();
        const allItemPromises = state.invoiceItemData.map((item) => {
            return priceListItem.byKey(item.priceListItemId).then((res) => {
                if (item.id) delete item.id;
                if (item.dispatchNoteId) delete item.dispatchNoteId;
                if (item.status) delete item.status;
                delete item.__KEY__;
                Object.assign(item, {
                    invoiceId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: state.admin.id,
                    modifiedById: state.admin.id,
                    createdDate: now.toISOString(),
                    modifiedDate: now.toISOString(),
                    priceListItem: JSON.stringify(res)
                });
                return invoiceItem.insert(item).then(() => {
                    totalDiscountAmount += item.discountAmount;
                    totalAmountDiscounted += Number(item.priceTotal);
                    totalPriceByQty += item.priceByQty;
                });
            });
        });
        await Promise.all(allItemPromises);
        const totalAmountAfterTax =
            totalAmountDiscounted * (1 + state.taxValue / 100);
        const totalTaxAmount = totalAmountAfterTax - totalAmountDiscounted;
        await invoice.update(e.key, {
            totalAmountTaxed: totalAmountAfterTax,
            totalTaxAmount: totalTaxAmount,
            totalAmountDiscounted: totalAmountDiscounted,
            totalDisocuntAmount: totalDiscountAmount,
            totalAmount: totalPriceByQty,
            user: JSON.stringify(userData)
        });
        const updatedInvoice = await invoice.byKey(e.key);
        const logData = [{ invoice: updatedInvoice }];
        const invoiceItems = await invoiceItem.load({ invoiceId: e.key });
        invoiceItems.forEach((item) => {
            logData.push({ item });
        });
        const changesLogData = {
            actionType: "INSERT",
            documentType: "invoice",
            oldValue: null,
            newValue: JSON.stringify(logData),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
        state.invoiceItemData = [];
    }
    function handleEditingStartInvoice(e) {
        state.editingInvoice = e.data;
        state.taxId = e.data.taxId;
        state.taxValue = e.data.taxValue;
        state.taxName = e.data.taxName;
        invoiceItem.load();
    }
    function handleEditCanceledInvoice() {
        state.invoiceItemData = [];
        state.rowsToBeDeleted = [];
    }
    async function handleRowUpdatingInvoice(e) {
        const now = new Date();
        let totalPriceByQty = 0;
        let totalDiscountAmount = 0;
        let totalAmountDiscounted = 0;
        const userData = e.newData.userId
            ? state.admin.domainId === 1
                ? await user.byKey(e.newData.userId)
                : await customer.byKey(e.newData.userId)
            : null;
        const allPromises = [];
        const snapshotInvoiceItems = [...state.invoiceItemData];
        const oldInvoice = await invoice.byKey(e.key);
        const oldInvoiceItems = await invoiceItem.load({ invoiceId: e.key });
        const oldDataLog = [{ invoice: oldInvoice }];
        oldInvoiceItems.forEach((item) => {
            oldDataLog.push({ item });
        });
        const deletePromises = state.rowsToBeDeleted.map((item) => {
            return invoiceItem.remove(item.id).then(() => {
                state.rowsToBeDeleted = [];
            });
        });
        allPromises.push(...deletePromises);
        const insertPromises = snapshotInvoiceItems.map((item) => {
            return priceListItem.byKey(item.priceListItemId).then((res) => {
                if (item.id) {
                    invoiceItem.remove(item.id);
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                Object.assign(item, {
                    invoiceId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: state.admin.id,
                    modifiedById: state.admin.id,
                    createdDate: now.toISOString(),
                    modifiedDate: now.toISOString(),
                    priceListItem: JSON.stringify(res)
                });
                return invoiceItem.insert(item).then(() => {
                    totalDiscountAmount += item.discountAmount;
                    totalAmountDiscounted += Number(item.priceTotal);
                    totalPriceByQty += item.priceByQty;
                });
            });
        });
        allPromises.push(...insertPromises);
        await Promise.all(allPromises);
        const totalAmountAfterTax =
            totalAmountDiscounted * (1 + state.taxValue / 100);
        const totalTaxAmount = totalAmountAfterTax - totalAmountDiscounted;
        const updateData = {
            totalAmountTaxed: totalAmountAfterTax,
            totalTaxAmount: totalTaxAmount,
            totalAmountDiscounted: totalAmountDiscounted,
            totalDisocuntAmount: totalDiscountAmount,
            totalAmount: totalPriceByQty
        };
        if (userData !== null) {
            updateData.user = JSON.stringify(userData);
        }
        await invoice.update(e.key, updateData);
        const updatedInvoice = await invoice.byKey(e.key);
        const updatedInvoiceItems = await invoiceItem.load({
            invoiceId: e.key
        });

        const newDataLog = [{ invoice: updatedInvoice }];
        updatedInvoiceItems.forEach((item) => {
            newDataLog.push({ item });
        });

        const changesLogData = {
            actionType: "UPDATE",
            documentType: "invoice",
            oldValue: JSON.stringify(oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: now
        };

        await changesLog.insert(changesLogData);
        state.invoiceItemData = [];
        eventBus.emit("newInvoiceItemAdded", e.key);
    }
    async function handleStatusChangeInvoice(e) {
        const oldDataLog = [{ invoice: e.row.data }];
        const updatedInvoice = await invoice.byKey(e.row.key);
        const newDataLog = [{ invoice: updatedInvoice }];

        const changesLogData = {
            actionType: "UPDATE",
            documentType: "invoice",
            oldValue: JSON.stringify(oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
    }
    function handleCancelInvoiceItem() {
        state.editingInvoiceItemOldData.pop();
        state.editingInvoiceItem = null;
    }
    function handleDeleteInvoiceItem(e) {
        state.invoiceItemData.splice(e.row.rowIndex, 1);
        if (e.row.data.id) {
            state.rowsToBeDeleted.push(e.row.data);
        }
    }
    function handleSaveNewInvoiceItem(qty, price, priceTotal) {
        const now = new Date();
        if (state.editingInvoiceItem !== null) {
            state.editingInvoiceItem.qty = qty;
            state.editingInvoiceItem.price = price;
            state.editingInvoiceItem.priceTotal = Number(priceTotal);
            state.editingInvoiceItem.modifiedDate = now.toISOString();
            state.editingInvoiceItem.modifiedById = state.admin.id;
            state.invoiceItemData.find((el) => {
                if (
                    state.editingInvoiceItem.__KEY__ &&
                    el.__KEY__ === state.editingInvoiceItem.__KEY__
                ) {
                    el = state.editingInvoiceItem;
                } else if (el.id === state.editingInvoiceItem.id) {
                    el = state.editingInvoiceItem;
                }
            });
            state.editingInvoiceItem = null;
        } else {
            state.editingInvoiceItem = null;
        }
    }
    function handleRowInsertingInvoiceItem(
        e,
        qty,
        price,
        priceTotal,
        discountAmount,
        discountPercentage,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: Number(priceTotal),
            discountAmount: discountAmount,
            discountPercentage: discountPercentage,
            priceListItemId: priceListItemId
        };
    }
    function handleEditingStartInvoiceItem(e) {
        state.editingInvoiceItem = e.data;
        const oldData = {
            ...state.editingInvoiceItem
        };
        state.editingInvoiceItemOldData.push(oldData);
    }
    async function handleGenerateDispatchNote(e) {
        const now = new Date();
        const invoiceData = e.row.data;
        let invoiceDataDispatchNotes = [];
        if (invoiceData.dispatchNoteId !== null) {
            invoiceDataDispatchNotes = JSON.parse(invoiceData.dispatchNoteId);
        }
        if (invoiceData.warehouseId === null) {
            const message = "Skladište nije odabrano.";
            return message;
        } else {
            const lastDispatchNote = await dispatchNote.load({
                businessYearId: invoiceData.businessYearId,
                warehouseId: invoiceData.warehouseId
            });
            // console.log(lastDispatchNote);
            const lastNumber =
                lastDispatchNote.length > 0
                    ? lastDispatchNote[0].number + 1
                    : 1;
            const dispatchNoteData = {
                number: lastNumber,
                date: now.toISOString(),
                userId: invoiceData.userId,
                invoiceId: invoiceData.id,
                priceTotal: invoiceData.totalAmountTaxed,
                remark: invoiceData.remark !== null ? invoiceData.remark : null,
                createdDate: now.toISOString(),
                modifiedDate: now.toISOString(),
                createdById: state.admin.id,
                modifiedById: state.admin.id,
                warehouseId: invoiceData.warehouseId,
                businessYearId: invoiceData.businessYearId,
                domainId: invoiceData.domainId,
                status: "active"
            };
            const dispatchNoteRes = await dispatchNote.insert(dispatchNoteData);
            const dispatchNoteItems = await invoiceItem.load({
                invoiceId: invoiceData.id
            });
            const insertPromises = dispatchNoteItems.map((item) => {
                delete item.id;
                delete item.invoiceId;
                delete item.priceByQty;
                delete item.discountAmount;
                delete item.discountPercentage;
                delete item.priceListItem;
                item.dispatchNoteId = dispatchNoteRes.id;
                item.status = "active";
                return item;
                // return Promise.all([
                //     dispatchNoteItem.insert(item),
                //     updateWarehouseItemStatusOnNewDispatchNote(
                //         item,
                //         invoiceData.warehouseId
                //     )
                // ]);
            });
            // await Promise.all(insertPromises);
            await warehouseItemStore.updateWarehouseItemStatusOnNewDispatchNote(
                {
                    warehouseId: dispatchNoteData.warehouseId,
                    items: insertPromises
                }
            );
            invoiceDataDispatchNotes.push(dispatchNoteRes.id);
            await invoice.update(invoiceData.id, {
                dispatchNoteId: JSON.stringify(invoiceDataDispatchNotes)
            });
        }
    }
    async function handleDeleteInvoice(e, focusedRowKey) {
        const oldInvoice = await invoice.byKey(e.row.data.id);
        const oldInvoiceItems = await invoiceItem.load({
            invoiceId: e.row.data.id
        });
        const oldDataLog = [{ invoice: oldInvoice }];
        oldInvoiceItems.forEach((item) => {
            oldDataLog.push({ item });
        });
        const invoiceData = await invoice.byKey(focusedRowKey);
        if (invoiceData.dispatchNoteId !== null) {
            const parsedDispatchNoteIds = JSON.parse(
                invoiceData.dispatchNoteId
            );
            await Promise.all(
                parsedDispatchNoteIds.map(async (dispatchNoteId) => {
                    const dispatchNoteData = await dispatchNote.byKey(
                        dispatchNoteId
                    );
                    // console.log(dispatchNoteData);
                    try {
                        const response =
                            await warehouseItemStore.updateWarehouseItemStatusOnRemovedDispatchNote(
                                {
                                    warehouseId: dispatchNoteData.warehouseId,
                                    dispatchNoteId: dispatchNoteId
                                }
                            );
                        if (!response.success) {
                            console.error(
                                "Error updating warehouse status:",
                                response.message
                            );
                            throw new Error(
                                response.message || "Unknown error occurred."
                            );
                        }
                        await dispatchNote.remove(dispatchNoteData.id);
                    } catch (error) {
                        console.error(
                            "Failed to update warehouse item status:",
                            error
                        );
                    }
                })
            );
        }
        await invoice.update(focusedRowKey, {
            status: "deleted",
            dispatchNoteId: null
        });
        const changesLogData = {
            actionType: "DELETE",
            documentType: "invoice",
            oldValue: JSON.stringify(oldDataLog),
            newValue: null,
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };

        await changesLog.insert(changesLogData);
    }

    async function handleSendEmailPopupData(selectedRowKey) {
        const invoiceData = await invoice.byKey(selectedRowKey);
        const invoiceUserData = JSON.parse(invoiceData.user);
        state.emailFormData.emailTo = invoiceUserData.email;
        state.emailFormData.senderId = state.admin.id;
        state.emailFormData.clientId = invoiceUserData.id;
        state.emailFormData.sourceId = selectedRowKey;
        state.emailFormData.dateSent = moment().format("YYYY-MM-DD HH:mm:ss");
        const domainUserData = await domainUser.byKey(state.admin.id);
        const businessYearData = await businessYear.byKey(
            invoiceData.businessYearId
        );
        const domainRes = await domain.byKey(domainUserData.domainId);
        if (domainRes) {
            const defaultEmailContent = JSON.parse(
                domainRes.defaultEmailContent
            );
            if (defaultEmailContent.ponuda) {
                if (defaultEmailContent.ponuda.subject !== null) {
                    if (defaultEmailContent.ponuda.subject.includes("{var}")) {
                        const subjectToReplace =
                            defaultEmailContent.ponuda.subject.replace(
                                "{var}",
                                `${invoiceData.createdById}-${invoiceData.number}/${businessYearData.year}`
                            );
                        state.emailFormData.subject = subjectToReplace;
                    } else {
                        state.emailFormData.subject =
                            defaultEmailContent.ponuda.subject !== null
                                ? defaultEmailContent.ponuda.subject
                                : null;
                    }
                }
                state.emailFormData.emailFrom =
                    defaultEmailContent.ponuda.email_from !== null
                        ? defaultEmailContent.ponuda.email_from
                        : null;

                state.emailFormData.message =
                    defaultEmailContent.ponuda.message !== null
                        ? defaultEmailContent.ponuda.message
                        : null;
            }
        }
        const emailSignatureDefaultRes = await emailSignatureDefault.byKey(
            domainUserData.domainId
        );
        if (domainUserData.emailSignature !== null) {
            state.signature = domainUserData.emailSignature;
        } else {
            state.signature = `--<br>
            <strong>${state.admin.username}</strong><br>
            ${emailSignatureDefaultRes.signature}`;
        }
    }
    async function handleExportingInvoiceRecap(table) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFileToVFS("@/assets/Poppins-Bold.ttf", poppinsBold);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.addFont("@/assets/Poppins-Bold.ttf", "Poppins-Bold", "bold");
        doc.setFont("Poppins-Regular", "normal");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        // const combinedFilter = table.getCombinedFilter(true);
        // let filterValueDate = null;
        // let filterValueYear = null;
        // if (combinedFilter && combinedFilter[0][0] === "date") {
        //     if (typeof combinedFilter.filterValue === "string") {
        //         filterValueDate = combinedFilter.filterValue;
        //     } else {
        //         filterValueYear = combinedFilter.filterValue;
        //     }
        // }
        // let formattedDateRange = null;
        // if (filterValueDate) {
        //     const [year, month] = filterValueDate.split("/").map(Number);
        //     const startDate = new Date(year, month - 1, 1);
        //     const endDate = new Date(year, month, 0);
        //     const formatDate = (date) => {
        //         const day = String(date.getDate()).padStart(2, "0");
        //         const month = String(date.getMonth() + 1).padStart(2, "0");
        //         const year = date.getFullYear();
        //         return `${day}.${month}.${year}`;
        //     };
        //     const startString = formatDate(startDate);
        //     const endString = formatDate(endDate);
        //     formattedDateRange = `Datum: od ${startString}. do ${endString}.`;
        // } else if (filterValueYear) {
        //     formattedDateRange = `Datum: Godina ${filterValueYear}.`;
        // } else {
        //     formattedDateRange = `Datum: Nije odabran`;
        // }
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            repeatHeaders: true,
            topLeft: { x: 1, y: 40 },
            columnWidths: [12, 24, 42, 18, 16, 18, 16, 18, 16],
            onRowExporting: (e) => {
                if (e.rowCells[0].backgroundColor === "#D3D3D3") {
                    e.rowHeight = 9;
                } else {
                    e.rowHeight = 13;
                }
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.padding = 0.5;
                pdfCell.borderColor = "#D3D3D3";
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.horizontalAlign = "center";
                    pdfCell.backgroundColor = "#D3D3D3";
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 9;
                }
            }
        }).then(() => {
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const generatedTime = getCurrentDateTime();
            const generated = `Vrijeme izrade: ${generatedTime}`;
            doc.setFontSize(10);
            doc.line(15, 47, pageWidth - 15, 47);
            doc.setFont("Poppins-Bold", "bold");
            doc.text("Rekapitulacija računa", 15, 45);
            doc.setFont("Poppins-Regular", "normal");
            doc.text(generated, pageWidth - 15, 45, { align: "right" });
            // doc.text(formattedDateRange, 15, 27);
            const documentName = "Rekapitulacija-računa.pdf";
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        }
                    });
                }
            });
        });
    }
    async function handleSendEmail(sendEmailFlag, selectedRowKey) {
        const dontChangeStatus = ["paid", "accepted", "rejected"];
        sendEmailFlag = true;
        let responseData;
        const pdfGenerationPromise = new Promise((resolve, reject) => {
            const pdfEventListener = (pdfForEmail) => {
                if (pdfForEmail && pdfForEmail.doc) {
                    resolve(pdfForEmail);
                } else {
                    reject(new Error("PDF generation failed"));
                }
            };
            eventBus.on("invoicePdfForEmail", pdfEventListener);
            eventBus.emit("exportInvoiceItemToPDF", sendEmailFlag);
        });

        try {
            const pdfFile = await pdfGenerationPromise;
            state.emailFormData.message =
                state.emailFormData.message.replace(/\\n/g, "<br>") +
                "<br>" +
                state.signature
                    .replace(/<\/p><p>/g, "<br>")
                    .replace(/<p>/g, "")
                    .replace(/<\/p>/g, "");

            const res = await invoice.byKey(selectedRowKey);
            const formData = new FormData();
            const pdfBlob = pdfFile.doc.output("blob");
            formData.append("file", pdfBlob, pdfFile.documentName);
            formData.append("json", JSON.stringify(state.emailFormData));

            const requestOptions = {
                method: "POST",
                body: formData
            };

            const response = await fetch(
                "http://billing.gtnet.hr/cli/procedure/emailLogUpload.php",
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            responseData = await response.json();
            if (!dontChangeStatus.includes(res.status)) {
                invoice.update(selectedRowKey, {
                    status: "pending"
                });
                sendEmailFlag = false;
                return { sendEmailFlag, responseData };
            }
        } catch (error) {
            console.error(
                "Error during PDF generation or email sending: ",
                error
            );
        } finally {
            sendEmailFlag = false;
            state.emailFormData = {
                sourceType: "proforma",
                sourceId: null,
                senderId: null,
                dateSent: null,
                clientId: null,
                emailFrom: null,
                emailTo: null,
                subject: null,
                message: null
            };
        }
    }
    async function generateInvoiceFromDispatchNotes(selectedRowKeys) {
        const { allDispatchNoteItems, userData, warehouseId } =
            await loadDispatchNoteData(selectedRowKeys);
        const invoiceData = await generateInvoiceData(
            state.admin.id,
            state.admin.domainId,
            state.businessYearId,
            warehouseId,
            null,
            userData,
            allDispatchNoteItems,
            selectedRowKeys
        );
        const newInvoice = await invoice.insert(invoiceData);
        await handleRowInsertedInvoice(
            {
                key: newInvoice.id,
                data: invoiceData
            },
            allDispatchNoteItems
        );
        await Promise.all(
            selectedRowKeys.map(async (dispatchNoteId) => {
                await dispatchNote.update(dispatchNoteId, {
                    invoiceId: newInvoice.id
                });
            })
        );
        return true;
    }
    async function loadDispatchNoteData(selectedRowKeys) {
        let allDispatchNoteItems = [];
        const userIds = new Set();
        const warehouseIds = new Set();
        await Promise.all(
            selectedRowKeys.map(async (dispatchNoteId) => {
                const dispatchNoteData = await dispatchNote.byKey(
                    dispatchNoteId
                );
                warehouseIds.add(dispatchNoteData.warehouseId);
                userIds.add(dispatchNoteData.userId);
                const dispatchNoteItems = await dispatchNoteItem.load({
                    dispatchNoteId: dispatchNoteId
                });
                allDispatchNoteItems =
                    allDispatchNoteItems.concat(dispatchNoteItems);
            })
        );
        if (userIds.size > 1) {
            throw new Error(
                "Provjerite otpremnice. Više različitih korisnika odabrano."
            );
        }
        if (warehouseIds.size > 1) {
            throw new Error(
                "Provjerite otpremnice. Više različitih skladišta odabrano."
            );
        }
        const userDataPromises = Array.from(userIds).map(async (userId) => {
            return state.admin.domainId === 1
                ? await user.byKey(userId)
                : await customer.byKey(userId);
        });
        const warehouseIdsArray = Array.from(warehouseIds);
        const warehouseId = warehouseIdsArray[0];

        const userDataArray = await Promise.all(userDataPromises);
        const userData = userDataArray[0];
        return { allDispatchNoteItems, userData, warehouseId };
    }
    return {
        ...toRefs(state),
        handleRowInsertingInvoice,
        handleRowInsertedInvoice,
        handleEditingStartInvoice,
        handleEditCanceledInvoice,
        handleRowUpdatingInvoice,
        handleCancelInvoiceItem,
        handleDeleteInvoiceItem,
        handleSaveNewInvoiceItem,
        handleRowInsertingInvoiceItem,
        handleEditingStartInvoiceItem,
        handleGenerateDispatchNote,
        handleDeleteInvoice,
        handleSendEmailPopupData,
        handleSendEmail,
        generateInvoiceFromDispatchNotes,
        handleExportingInvoiceRecap,
        getDomainData,
        updateTaxValue,
        handleStatusChangeInvoice
    };
}
